import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

import { Messages as MessagesData, fileTypesAllowedCollection } from './data';
import { Message } from './chat.model';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { InterComponentDataService } from '../services/inter-component-data.service';
import { BehaviorSubject, EMPTY, of, Subject, Subscription, timer } from 'rxjs';
import { catchError, concatMap, debounceTime, tap } from 'rxjs/operators';
import { ChatService } from '../services/chat.service';
import { UserVerifyService } from 'src/app/services/user-verify.service';
import { DatePipe } from '@angular/common';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { ToastAlertsService } from 'src/app/reusables/toast-alerts/toast-alerts.service';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { HttpEventType } from '@angular/common/http';

class HiddenKeyConstant {
  static DEFAULT = "hidden";
  static MS = "msHidden";
  static WEB_KIT = "webkitHidden";
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

/**
 * Chat-component
 */
export class IndexComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptionArr: Subscription = new Subscription(); // for tracking all subscription on page

  hideSideMenuForNow = true;// to hide Side Menu feature not implemented for now
  hideFeaturesForNow = true;// to hide feature not implemented for now

  // Set the name of the hidden property and the change event for tab/page visibility
  docHidden;
  visibilityChange;
  tabVisibilitylistener:any // checks whether tab is being viewed actively

  activetab = 2;
  MessagesData: Message[];
  newMessage: Message|any;
  tempMsgListHoarder: Message[] = [];// reuired for collecting old msg list when we scroll
  messageList:  Message[] = [];
  lastMessageId = '';
  jumpedToSpecificChat = false;
  currentFromUID: string;
  fromUIDChatHeadInfo:any;
  userType = '' // only used for sending group type msgs
  loggedInUserRole = '' // only currently logged in user role
  showStartChatBanner = true;
  showLoader = false;

  limitIndex: any // used for storing limit index used while scrolling for content
  actualMsgLengthRecieved = 0; // only active when limit index is available for knowing how many msgs actually recieved while scrolling up for more msgs

  hitGetMessagesOnScrollEvent = new Subject<any>();

  /* Req since by default this event is fired when we load msgs for 1st time */
  ignoreDefault1stScrollToYStart = true;

  /* Ref= https://stackoverflow.com/questions/58325567/ngx-infinite-scroll-scrolled-event-wont-stop-firing */
  stopScrollCheck: boolean = false;// for stopping scrolling
  scrollUpApiHitThreshold = 1.5; // when 15% from top or 85% of content has been scrolled up(see logic on ngx-infinite-scroll 'npm' page)
  scrollDownApiHitThreshold = 0.5; // when 5% from bottom or 95% of content has been scrolled down(see logic on ngx-infinite-scroll 'npm' page)
  scrollToSomePercent = 0.16; // here 16%, it is 1% extra than scrollUpApiHitThreshold 

  scrollUpEvent = false;

  onlyDistinctConsecutiveMsgId = ''; // for allowing only 2 distinct consecutive msgId hits to backend read update api

  usrChatSrchQuery: any; // bind this to input with ngModel
  usrChatSrchList = [];
  chatSrchQueryChanged: Subject<string> = new Subject<string>();
  srchTurnedOn = false; // used for messages loading format change when any srch result is jumped to in chat
  jumpToSearchedMsgId = ''; // only used for jumping to searched msg
  srchDirection = 'prev' || 'next';
  srchHelperDetails: any; // helper for scrolling data, when srch results are jumped to and initiated
  isLoadingSrchData = false;
  
  private isLoadingDataBehaviorSubject:BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoadingDataObs = this.isLoadingDataBehaviorSubject.asObservable();
  isLoadingData = false;


  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  lang: string;

  @ViewChild(InfiniteScrollDirective, {read: ElementRef}) infScrollElement:ElementRef;

  @ViewChild('scrollframe', {static: false}) scrollFrame: ElementRef;
  @ViewChildren('itemForScroll', {read: ElementRef}) itemElementsForScroll: QueryList<ElementRef>;
  @ViewChild('banUserInformContent') myBanUserInformContent: ElementRef;

  /* For Ref on perfect-scrollbar
  1> https://stackoverflow.com/questions/57194862/how-to-call-ngx-perfect-scrollbar-update-and-scrolltop-method-in-angular
  2> https://www.npmjs.com/package/ngx-perfect-scrollbar - See doc here
  */
  // Linking to component that using perfect-scrollbar
  @ViewChild('perfectScroll', {static: false}) public directiveScroll?: PerfectScrollbarComponent;

  private scrollContainer: any;

  constructor(private authFackservice: AuthfakeauthenticationService, private authService: AuthenticationService,
    private router: Router, public translate: TranslateService, private modalService: NgbModal, private offcanvasService: NgbOffcanvas
    , private dataService: InterComponentDataService, private chatService: ChatService
    , private userService: UserVerifyService, private datePipe: DatePipe, private cdref: ChangeDetectorRef,
    private renderer : Renderer2, private toastService: ToastAlertsService) {
      console.log('constructor()');
      // this.hitGetMessagesOnScrollEvent
      // .pipe(debounceTime(1000)) // wait 300ms after the last event before emitting last event
      // .subscribe(model => {
      //   console.log('CCOUNTER()');
      //   // this.dataService.setDataToSend({
      //   //   eventName: 'GetMessages', //eventname can be anything
      //   //   data: { fromUID: this.dataService.currentFromUID, toUID: this.fromUIDChatHeadInfo['toUID'], fIndex: this.fromUIDChatHeadInfo['index'] }
      //   // });
      // });
      this.defineBrowserSupportForTabVisibility();
      if (! this.tabVisibilitylistener){
        this.tabVisibilitylistener = this.renderer.listen(document, this.visibilityChange, this.handleVisibilityChange.bind(this));
      }

      const sub1 = this.chatSrchQueryChanged
      .pipe(debounceTime(500))
      .subscribe(qry => {
        console.log('query inside chatSrchQueryChanged subscription: ', qry);
          this.usrChatSrchQuery = qry.trim();
          // api call
          // if(this.usrSrchQuery) {
            this.getDataFromSearchAPI(this.usrChatSrchQuery);
          // }
      });
      this.subscriptionArr.add(sub1);
  }

  ngOnInit(): void {
    console.log('ngOnInit()');
    this.isLoadingDataObs.pipe(debounceTime(500))
    .subscribe(x => {
      console.log('LOADING CCOUNTER()', x);
      this.isLoadingData = x;
      // this.cdref.detectChanges();//throttle(async (ev) => (ev.eventName === 'typingStatusRecieved') ? throttleTime(1000) : timer(0))
    });
    let sub1 = this.dataService.dataReceivedEvent.pipe(concatMap((result: any) => {
      console.log('result :: ', result);
      if (result.eventName === 'GetMessages') {
        this.showStartChatBanner = false;// set it false when we start the loader for getting messages
        console.log('result :: ', result.data);
        if(result.data.hasOwnProperty('type') && result.data['type'] === 'group'){
          this.userType = result.data['type'];
        } else {
          this.userType = ''; // set it empty for non-group
        }
        if(result.data.hasOwnProperty('fIndex')){

          this.limitIndex = result.data['fIndex'];
          // this.tempMsgListHoarder = [...this.messageList];
          // console.log('current hoarded Msg List :: ', this.tempMsgListHoarder);
          // this.messageList.length=0;
        } 
        else if(this.jumpToSearchedMsgId){
        } 
        else {
          if(this.chatService.getInitialMessagesContentWindowLoaderSuppressStatus()) {
            this.showLoader = false;
            setTimeout(() => {
              this.chatService.setInitialMessagesContentWindowLoaderSuppressStatus(false);
            }, 0);
          } else {
            this.showLoader = true;// only when initial load of getMessages, not on scroll based fetch
          }
        }

        if(result.hasOwnProperty('resetScrollbar')){/* This fires when we change user or group */
        console.log('@@-- Resetting --@@');
          // this.directiveScroll.directiveRef.elementRef.nativeElement.scrollTop = 0;
          this.messageList.length=0;
          this.tempMsgListHoarder.length=0;
          this.limitIndex = 0;
          this.fromUIDChatHeadInfo = null;
          this.srchHelperDetails = null;
          this.actualMsgLengthRecieved = 0;
          this.stopScrollCheck = false;
          this.onlyDistinctConsecutiveMsgId = '';
          this.scrollUpApiHitThreshold = 1.5; // resetting threshold to default
          this.scrollDownApiHitThreshold = 0.5; // resetting threshold to default
          this.lastMessageId = result.lastMsgId;
          this.srchTurnedOn = false;
          this.jumpToSearchedMsgId = '';
          this.clearUserChatSearch();
          if(result.hasOwnProperty('jumpedDirectlyToChat') && result.jumpedDirectlyToChat){
            this.jumpedToSpecificChat = result.jumpedDirectlyToChat;
          } else {
            this.jumpedToSpecificChat = false;
          }
        }
        return of(result.data)//.pipe(debounceTime(600));
      } 
      else if(result.eventName === 'SomeMessageRecieved') {
        if(this.fromUIDChatHeadInfo && (this.fromUIDChatHeadInfo.hasOwnProperty('name'))) {
          const tempObj = result.data;
          if(tempObj.from === this.fromUIDChatHeadInfo.toUID) {
            this.messageList.push({/* This will only be pushed to ur screen only */
              id: tempObj.from,
              name: this.capitalizeFirstLetter(tempObj.fromName),
              message: tempObj.msg,
              msgId: tempObj.msgId,
              isRead: tempObj.isRead,
              align: 'left',
              unixTime:this.getUnixTimeFromIsoTime(tempObj.time),
              time:tempObj.time,
              isToday:true,// since current recieve happens today only
              onlyTime:this.isoTimeTransform(tempObj.time),
              dateTime: this.isoTimeTransform(tempObj.time, false, true),
              noTime:this.isoTimeTransform(tempObj.time, true),
              ...(tempObj.fileSize && {fileSize:this.customFormatBytes(tempObj.fileSize)}),
              ...(tempObj.fileSize && {fileName:tempObj.fileName}),
              ...(tempObj.fileSize && {isFile:tempObj.isFile}),
              ...(tempObj.fileSize && {fileContent:tempObj.msg})
            });
            this.groupMsgsByNameAndDateAlt(this.messageList);
            this.cdref.detectChanges();
            // this.animateScroll(this.messageList.length-1);// we call auto-scroll whenever we add msg
            this.altJumpToBottom();
            /* Testing socket read reciept start*/
            // const postData = {
            //   userId: this.currentFromUID,
            //   msgIds:[tempObj.msgId?tempObj.msgId:'']
            // }
            // this.chatService.SendReadRecieptThruSocket(postData);
            /* Testing socket read reciept end*/
          } else if(tempObj.hasOwnProperty('groupId') && (tempObj.groupId === this.fromUIDChatHeadInfo.toUID)) {
            this.messageList.push({/* This will only be pushed to ur screen only */
              id: tempObj.from,
              name: this.capitalizeFirstLetter(tempObj.fromName),
              message: tempObj.msg,
              msgId: tempObj.msgId,
              isRead: tempObj.isRead,
              align: 'left',
              unixTime:this.getUnixTimeFromIsoTime(tempObj.time),
              time:tempObj.time,
              isToday:true,// since current recieve happens today only
              onlyTime:this.isoTimeTransform(tempObj.time),
              dateTime: this.isoTimeTransform(tempObj.time, false, true),
              noTime:this.isoTimeTransform(tempObj.time, true),
              ...(tempObj.fileSize && {fileSize:this.customFormatBytes(tempObj.fileSize)}),
              ...(tempObj.fileSize && {fileName:tempObj.fileName}),
              ...(tempObj.fileSize && {isFile:tempObj.isFile}),
              ...(tempObj.fileSize && {fileContent:tempObj.msg})
            });
            this.groupMsgsByNameAndDateAlt(this.messageList);
            this.cdref.detectChanges();
            // this.animateScroll(this.messageList.length-1);// we call auto-scroll whenever we add msg
            this.altJumpToBottom();
            /* Testing socket read reciept start*/
            // const postData = {
            //   userId: this.currentFromUID,
            //   msgIds:[tempObj.msgId?tempObj.msgId:''],
            //   ...(this.userType && {type:this.userType})
            // }
            // this.chatService.SendReadRecieptThruSocket(postData);
            /* Testing socket read reciept end*/
          }
        }
        /* Stopping pipe stream chain in case non-required events fire as it 
        creates error in next concatMap request in pipeline*/
        return EMPTY;
      } 
      else {
        if (result.eventName === 'typingStatusRecieved') {
          if(this.fromUIDChatHeadInfo && (this.fromUIDChatHeadInfo.hasOwnProperty('toUID'))) {
            if (this.userType) {
              if (this.fromUIDChatHeadInfo.toUID == result.data.toUID) {/* Only for group */
                if (this.fromUIDChatHeadInfo.isTyping) {// do nothing and ignore subsequent 'typing' event until "this.fromUIDChatHeadInfo.isTyping === false"

                } else {
                  this.fromUIDChatHeadInfo.isTyping = true;
                  this.fromUIDChatHeadInfo.typerName = this.capitalizeFirstLetter(result.data.fromName);
                  setTimeout(() => {
                    this.fromUIDChatHeadInfo.isTyping = false;
                  }, 1000);
                  // this.cdref.detectChanges();
                  // tElem.push(x);
                }
              }
            } else {/* for non group */
              if(this.fromUIDChatHeadInfo.toUID == result.data.fromUID) {
                if(this.fromUIDChatHeadInfo.isTyping){// do nothing and ignore subsequent 'typing' event until "this.fromUIDChatHeadInfo.isTyping === false"

                } else {
                  this.fromUIDChatHeadInfo.isTyping = true;
                  this.fromUIDChatHeadInfo.typerName = this.capitalizeFirstLetter(result.data.fromName);;
                  setTimeout(() => {
                    this.fromUIDChatHeadInfo.isTyping = false;
                  }, 1000);
                  // this.cdref.detectChanges();
                  // tElem.push(x);
                }
              }
            }
          }
        } else if (result.eventName === 'onlineStatusRefreshed') {
          if(this.fromUIDChatHeadInfo && (this.fromUIDChatHeadInfo.hasOwnProperty('toUID'))) {
              if(this.fromUIDChatHeadInfo.toUID == result.data.userId) {
                this.fromUIDChatHeadInfo.status = result.data.status;
                this.cdref.detectChanges();
                // tElem.push(x);
              }
          }
        } else if (result.eventName === 'msgReadRecieptRecieved') {
          if(this.messageList && (this.messageList.length>0)) {
            let tElem = [];
            const foundIndex = this.messageList.findIndex(x => {
              if(x.msgId == result.data.msgIds) {
                x.isRead = true;
                this.cdref.detectChanges();
                // tElem.push(x);
                return true;
              }
            });
            // console.log('<< $$In msgReadRecieptRecieved event messageList $$ >> :: ', this.messageList);
            // console.log('<< $$In msgReadRecieptRecieved event $$ >> :: ', foundIndex, tElem);
            // this.messageList[foundIndex] = item;
          }
        }
        else if (result.eventName === 'msgSentAcknowledgedRecieptRecieved') {
          if(this.fromUIDChatHeadInfo && (this.fromUIDChatHeadInfo.hasOwnProperty('toUID'))) {
            if(result.data.to === this.fromUIDChatHeadInfo.toUID) {
              if(this.messageList && (this.messageList.length>0)) {
                // let tElem = [];
                this.messageList[this.messageList.length-1]['msgId'] = result.data.msgId;
                this.cdref.detectChanges();
                // const foundIndex = this.messageList.findIndex(x => {
                //   if(x.msgId == result.data.msgIds) {
                //     x.isRead = true;
                //     tElem.push(x);
                //     return true;
                //   }
                // });
                // console.log('<< $$In msgReadRecieptRecieved event messageList $$ >> :: ', this.messageList);
                // console.log('<< $$In msgReadRecieptRecieved event $$ >> :: ', foundIndex, tElem);
                // this.messageList[foundIndex] = item;
              }
            }
          }
        }
        else if (result.eventName === 'userBanRecieved') {
          this.modalService.open(this.myBanUserInformContent).result.then(
            (resolveResult) => {
              console.log(`Closed with: ${resolveResult}`);
              window.location.reload();
            },
            (rejectReason) => {
              console.log(`Dismissed with: ${rejectReason}`);
              window.location.reload();
            },
          )
        }
        else if (result.eventName === 'YourUserId') {
          this.currentFromUID = result.data;
          this.loggedInUserRole = this.chatService.currentLoggedInUserRole;
          console.log('<< $$currentFromUID in index.ts$$ >> :: ', this.dataService.currentUserObj);
        }
        /* Stopping pipe stream chain in case non-required events fire as it 
        creates error in next concatMap request in pipeline*/
        return EMPTY;
      }
    }),
      concatMap((resData: any) => {
        console.log('resData :: ', resData);
        if(resData.hasOwnProperty('fIndex') || this.jumpToSearchedMsgId){// only showing when loading more messages/src results jumped as we already have showLoader at initial first load
          this.isLoadingDataBehaviorSubject.next(true);
        }
        return this.userService.getInitialMessages(resData).pipe(tap((response) => {
          this.isLoadingDataBehaviorSubject.next(false);
          return response;
          }),
          /* Very important to keep outer observable subscription alive if
          inner observable errors out in concatMap*/
          catchError((error) => {
            console.log('!! error in catchError !!', error);
            this.isLoadingDataBehaviorSubject.next(false);
            this.showDanger('Something went wrong :: '+ error);
            this.stopScrollCheck = false;
            return EMPTY; // as catchError requires an observable to be emitted 
          }));
      })).subscribe((resp: any) => {
        this.showStartChatBanner = false;
        this.showLoader = false;
        console.log('resp in getInitialMessages:: ', resp);
        const { Messages, ...tempArr } = resp.data;
        this.fromUIDChatHeadInfo = tempArr;
        this.fromUIDChatHeadInfo.name = this.capitalizeFirstLetter(this.fromUIDChatHeadInfo.name);
        const tempCurrentFromUID = this.dataService.currentFromUID;
        if(Messages && (Messages.length>0)){
          if(this.srchTurnedOn){
            if(this.jumpToSearchedMsgId) {// creation of both limits only one time when we jump from search options list
              this.srchHelperDetails = {
                firstDocumentNumberForPrev: Messages[0].documentNumber,
                lastDocumentNumberForNext: Messages[Messages.length-1].documentNumber
              }
            }
            if(this.srchDirection==='prev'){
              this.srchHelperDetails.firstDocumentNumberForPrev = Messages[0].documentNumber;
            } else if(this.srchDirection==='next'){
              this.srchHelperDetails.lastDocumentNumberForNext = Messages[Messages.length-1].documentNumber;
            }
          }
          if(this.limitIndex){/* only while fetching more msgs and not on initial getMessages*/
            this.tempMsgListHoarder = [...this.messageList];
            console.log('current hoarded Msg List :: ', this.tempMsgListHoarder);
            this.actualMsgLengthRecieved = Messages.length;
            this.messageList.length=0;
          }
          const today = new Date();
          const tday = today.toISOString();
          console.log('date now', today.getDate(), tday);
          this.messageList = Messages.map((elem: any)=> {
            // const today = new Date();
            // const tday = today.toISOString();
            // console.log('date now', today.getDate());
            elem['align'] = (tempCurrentFromUID===elem.id)?'right':'left'; // only right align the logged in self chat user
            console.log('elem[time]', elem['time']);
            elem['unixTime'] = this.getUnixTimeFromIsoTime(elem['time']);
            elem['onlyTime'] = this.isoTimeTransform(elem['time']);
            elem['dateTime'] = this.isoTimeTransform(elem['time'], false, true);
            elem['noTime'] = this.isoTimeTransform(elem['time'], true);
            elem['isToday'] = (this.isoTimeTransform(elem['time'], true)===this.isoTimeTransform(tday, true));
            elem['hideName'] = false;
            elem.name = this.capitalizeFirstLetter(elem.name);
            if(elem.fileSize) {
              elem.fileSize = this.customFormatBytes(elem.fileSize);
              elem.fileContent = elem.message;
            }
            return elem;
          });
        } else {// When no more msgs are available to be fetched
          // this.messageList = this.messageList.concat(this.tempMsgListHoarder);
          // this.cdref.detectChanges();
          // this.directiveScroll.directiveRef.scrollToTop(250, 1000);
          // this.isLoadingDataBehaviorSubject.next(false);
          
          // this.directiveScroll.directiveRef.scrollToTop(250, 1000);/* temporary turned off */
          // this.animateScroll(2);
          if(this.limitIndex){/* only while fetching more msgs and not on initial getMessages*/
            if(this.srchTurnedOn) {
              console.log('this.fromUIDChatHeadInfo :: ', this.fromUIDChatHeadInfo);
              if(this.srchDirection === 'next') {
                this.scrollDownApiHitThreshold = 0;
                this.srchHelperDetails.lastDocumentNumberForNext = null;
              } else if (this.srchDirection === 'prev'){
                this.scrollUpApiHitThreshold = 0;
                this.srchHelperDetails.firstDocumentNumberForPrev = null;
              }
            } else {
              this.scrollUpApiHitThreshold = 0;
            }
            // this.showInfo('No more data available');
          } else {
            // this.showInfo('No data available');
          }
          this.stopScrollCheck = false;
          return;
        }
        if(this.tempMsgListHoarder && (this.tempMsgListHoarder.length>0)) {
          console.log('this.tempMsgListHoarder in getInitialMessages:: ', this.tempMsgListHoarder);
          if(this.srchTurnedOn && this.srchDirection === 'next') {
            this.messageList = this.tempMsgListHoarder.concat(this.messageList);
          } else {// in case of no srch or srcDirection is 'prev'
            this.messageList = this.messageList.concat(this.tempMsgListHoarder);
          }
          // this.messageList = this.messageList.map((elem:any) => elem);
          // this.cdref.detectChanges();
          this.tempMsgListHoarder.length = 0;
        }
        let tArr = this.newGenerateItems(this.messageList);
        console.log('%c newGenerateItems() :: ', "background: blue; color: #bada55", tArr);
        // this.isLoadingDataBehaviorSubject.next(false);
        this.groupMsgsByNameAndDateAlt(this.messageList);
        console.log('this.messageList in getInitialMessages:: ', this.messageList);
        // if(this.ignoreDefault1stScrollToYStart) {
        //   console.log('SCROLLING in getInitialMessages:: ');
          // this.cdref.detectChanges();
        //   this.scrollToBottom();
        // } else {
        //   this.scrollToBottom(500);
        // }
        // this.scrollToBottom();
        // setTimeout(() => {
          
        // // this.directiveScroll.directiveRef.update();
        // this.scrollToBottom(0,'li:nth-child(5)');
        this.cdref.detectChanges();
        this.scrollContainer = this.renderer.selectRootElement(this.infScrollElement.nativeElement , true);
        if(this.actualMsgLengthRecieved && (this.actualMsgLengthRecieved>0)) {
          // this.messageList = [].concat(this.messageList);
          // this._ngZone.runOutsideAngular(() => {
          console.log('&& Before - this.scrollContainer scrollTop :: ', this.scrollContainer.scrollTop, " scrollHeight :: ", this.scrollContainer.scrollHeight);
          //   this.isLoadingDataBehaviorSubject.next(true);
          // setTimeout(() => {
          //   // this.cdref.detectChanges();
          //   this.isLoadingDataBehaviorSubject.next(false);
          //   this.animateScroll(this.actualMsgLengthRecieved-1); // 4 as max index because currently we fetch in limit of 5
          //   // this.scrollContainer.scrollTo(0, 300);
          if(this.srchTurnedOn) {
            if(this.srchDirection === 'next') {/* We leave it without any scroll config as it by default auto resets scroll to desired position*/
              // this.scrollContainer.scrollTo(0, (0.94) * this.scrollContainer.scrollHeight);
              // this.scrollContainer.scrollTop = 0

            } else if (this.srchDirection === 'prev'){
              this.scrollContainer.scrollTo(0, this.scrollToSomePercent * this.scrollContainer.scrollHeight);
            }
          } else {
            this.scrollContainer.scrollTo(0, this.scrollToSomePercent * this.scrollContainer.scrollHeight);
          }
          this.stopScrollCheck = false;
          //   // setTimeout(() => {
          //   //   // this.scrollContainer.scrollTo(0, 300);
          //   //   console.log('&& After - this.scrollContainer scrollTop :: ', this.scrollContainer.scrollTop, " scrollHeight :: ", this.scrollContainer.scrollHeight);
          //   // },3000
          //   // );
          //   // this.animateScroll()
          // },3000
          // );
          // ;})
        } else {
          if(!this.jumpToSearchedMsgId){
            this.altJumpToBottom();
          } else {
            // this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight/2;
            // this.cdref.detectChanges();
            // const elem = this.renderer.selectRootElement('li#'+this.jumpToSearchedMsgId , true);
            // console.log('#+this.jumpToSearchedMsgId :: ', elem);
            document.getElementById(this.jumpToSearchedMsgId).scrollIntoView({
              behavior: 'auto',
              block: 'center',
              inline: 'center'
            });
            this.jumpToSearchedMsgId = '';
          }
          // this.animateScroll(this.messageList.length-1);
        }
        
        // }, 2000);/* temporary turned off */
      },
      (err: any) => {
      //   this.loading1 = false;
      //   localStorage.clear();
        console.log('error Status', err);
        this.showDanger('Something went wrong :: '+ err);
        // if (err.status === 401) { // When unauthenticated access due to wrong token or session expire
        //   this.saveRedirectUrlAndLogoutToMainPage();
        // } else { // When unauthenticated access due to reasons like partner info not available, server down
        //   this.userService.logout();
        // }

        // no user login so redirect to login page
        // this.router.navigate(['']);
        // observer.next(false);
        // observer.complete();
      }, () => console.log('complete'));
    this.subscriptionArr.add(sub1);
    // this.MessagesData = MessagesData;

    this.lang = this.translate.currentLang;
  }

  ngAfterViewInit() {
    if (document[this.docHidden]) {
      console.log('Tab Hidden (once on initial load only)');
    } else {
      console.log('Tab Shown (once on initial load only)');
    }
    console.log('dataService.currentFromUID', this.dataService.currentFromUID);
    if (this.dataService.currentPageReloadStatus) {/* Only on page reload */
      console.log('[[recieving pageReloading]]');
      console.log('BrowserRefresh', this.dataService.currentPageReloadStatus);
      this.chatService.sendUserIdForJoining(this.dataService.currentFromUID);
    }
    /* We call auto-scroll whenever we add or get msg */
    this.itemElementsForScroll.changes.subscribe(data => {
      console.log('[[this.itemElementsForScroll.changes]]', data);
      if(this.scrollUpEvent) {
        // this.cdref.detectChanges();
        // setTimeout(() => {
          
        
        //   // data.last.nativeElement.scrollIntoView();
        // }, 1000);
        // let el = document.getElementsByClassName('mtem_5')[0];
        // el.scrollIntoView();
        
      // console.log('[[this.itemElementsForScroll.changes]]', el);
        this.scrollUpEvent = false;
      }
    //   if(this.actualMsgLengthRecieved && (this.actualMsgLengthRecieved>0)) {
    //     console.log('[[this.itemElementsForScroll.changes]]', data);
    //     // this.messageList = [].concat(this.messageList);
    //     setTimeout(() => {
    //     //   // this.cdref.detectChanges();
    //       this.animateScroll(this.actualMsgLengthRecieved-1); // 4 as max index because currently we fetch in limit of 5
    //     }, 1800);
    //   }
    });
  }

	showInfo(msg) {
		this.toastService.show(msg, { classname: 'bg-info text-light', delay: 3000 });
	}

  showDanger(msg) {
    this.toastService.show(msg, { classname: 'bg-danger text-light', delay: 3000 });
  }


  customTimeoutTracker(callback, delay:any) {
    let id, started, remaining:any = delay, running

    function start(){
      running = true
      started = new Date()
      id = setTimeout(callback, remaining)
    }

    function pause () {
      running = false
      clearTimeout(id);
      let t:any = new Date();
      remaining -=  t - started
    }

    function getTimeLeft () {
      if (running) {
        this.pause()
        this.start()
      }

      return remaining
    }

    function getStateRunning () {
      return running
    }

    start()
  }
  
  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private defineBrowserSupportForTabVisibility() {
    if (typeof document[HiddenKeyConstant.DEFAULT] !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      this.docHidden = HiddenKeyConstant.DEFAULT;
      this.visibilityChange = "visibilitychange";
    } else if (typeof document[HiddenKeyConstant.MS] !== "undefined") {
      this.docHidden = HiddenKeyConstant.MS;
      this.visibilityChange = "msvisibilitychange";
    } else if (typeof document[HiddenKeyConstant.WEB_KIT] !== "undefined") {
      this.docHidden = HiddenKeyConstant.WEB_KIT;
      this.visibilityChange = "webkitvisibilitychange";
    }
  }

  // If the page is hidden, log it;
  // if the page is shown, log it;
  handleVisibilityChange() {
    if (document[this.docHidden]) {
      console.log('Tab Hidden');
    } else {
      console.log('Tab Shown');
    }
  }

  onIntersectionVisibilityChanged(elemData, status: any) {
    
    console.log('%c onIntersection()', "background: black; color: #bada55", elemData);
    if (!elemData.msgId) {
      return;
    } else if(!this.onlyDistinctConsecutiveMsgId) {
      this.onlyDistinctConsecutiveMsgId = elemData.msgId;
    } else if (this.onlyDistinctConsecutiveMsgId) {
      if (elemData.msgId && (this.onlyDistinctConsecutiveMsgId === elemData.msgId)) {
        this.onlyDistinctConsecutiveMsgId = elemData.msgId;// replacing with new value before returning
        return;
      } else if (elemData.msgId && (this.onlyDistinctConsecutiveMsgId !== elemData.msgId)) {
        this.onlyDistinctConsecutiveMsgId = elemData.msgId;
      }
    }
    const postData = {
      userId: this.currentFromUID,
      msgIds:[elemData.msgId?elemData.msgId:''],
      ...(this.userType && {type:this.userType})
    }
    // this.chatService.SendReadRecieptThruSocket(postData);
    // return;
    const sub = this.userService.postReadUpdateData(postData).subscribe((res: any)=>{
      
      /* We are sending for updating/refreshing userList's unread msg count in recent chats*/
      this.chatService.refreshUserList(true);
    },
      (err: any) => {
        console.log('onIntersectionVisibilityChanged [[error Status]] ::', err);
      });
    // this.visibilityStatus[index] = status;

    this.subscriptionArr.add(sub);
  }
  
  isElementInScrollContainerViewport (el) {
      let rect = el.getBoundingClientRect();
  
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (this.scrollContainer.clientHeight) &&
          rect.right <= (this.scrollContainer.clientWidth)
      );
  }

  getCurrentLoggedInUID() {
    console.log('dataService.currentFromUID in getCurrentLoggedInUID()', this.dataService.currentFromUID);
    this.dataService.setDataToSend({
      eventName: 'YourUserId', //eventname can be anything
      data: this.dataService.currentFromUID
    });
  }

  groupMsgsByNameAndDateAlt(arr) {
    /* For msg grouping logic */
    let c = 0;
    let dateSame = false;
    let allDiffDate = [];
    let allSameUserIdForBubbleFin = [];
    let userIdSame = false;
    for (let i = 0; i < arr.length - 1; i++) {
      let j = i+1;// always next element

      if (arr[i]['noTime'] === arr[j]['noTime']) {
        allDiffDate.length = 0;//resetting when we have same dates
        if(dateSame){
          arr[i]['hideTime'] = true;
          arr[j]['hideTime'] = true;
        } else {
          dateSame = true;
          arr[i]['hideTime'] = false;
          arr[j]['hideTime'] = true;
        }
        if (arr[i]['id'] === arr[j]['id']) {/* Comparing against userId instead of name*/
          if(userIdSame){
            arr[i]['hideName'] = true;
            if(j===(arr.length - 1)) { // last item in msg array
              arr[j]['hideName'] = false;
            } else {
              arr[j]['hideName'] = true;
            }
          } else {
            userIdSame = true;
            allSameUserIdForBubbleFin.push(arr[i]['id']);
            arr[i]['addChatBubbleFin1'] = true;//only added for first msg bubble while grouping under same userId
            arr[i]['hideName'] = true;
            arr[j]['hideName'] = false;
          }
        } else {
          userIdSame = false;
          
          if(!allSameUserIdForBubbleFin.includes(arr[i]['id'])){
            arr[i]['addChatBubbleFin1'] = true;
          }
          arr[j]['addChatBubbleFin1'] = true;//added for every msg bubble when userId don't match
          arr[i]['hideName'] = false;
          arr[j]['hideName'] = false;
          allSameUserIdForBubbleFin.length = 0;//resetting when we have diff userId

        }
      } else {
        dateSame = false;
        allDiffDate.push(arr[j]['noTime']);   
        if(!allSameUserIdForBubbleFin.includes(arr[i]['id'])){
          arr[i]['addChatBubbleFin1'] = true;
        }
        if(i===0){ // no previous date available
          arr[i]['hideTime'] = false;
        } else {
          if(allDiffDate.includes(arr[i]['noTime'])){
            arr[i]['hideTime'] = false;
          } else {
            arr[i]['hideTime'] = true;
          }
        }
        arr[j]['addChatBubbleFin1'] = true;//added for every msg bubble when dates don't match
        arr[j]['hideTime'] = false;

        userIdSame = false;
        arr[i]['hideName'] = false;
        // } else {
        //   arr[i]['hideName'] = true;
        // }
        arr[j]['hideName'] = false;
        allSameUserIdForBubbleFin.length = 0;//resetting when we have same userId but diff dates
      }
    }
  }

  groupMsgsByName(arr) {
    /* For msg grouping logic */
    let c = 0;
    for (let i = 0; i < arr.length - 1; i++) {
      if(arr[i].hasOwnProperty('type') && (arr[i].type==='day')){
        continue;
      }
      for (let j = i + 1, swapping; j < arr.length; j++) {
        if(arr[j].hasOwnProperty('type') && (arr[j].type==='day')){
          break; // break out of current for loop
        }
        if (arr[i]['name'] === arr[j]['name']) {
          arr[i]['hideName'] = true;
          arr[j]['hideName'] = false;
        } else {
          // continue;
          arr[i]['hideName'] = false;
          arr[j]['hideName'] = false;
        }
      }
    }
  }

  /*Refer Link - https://stackoverflow.com/questions/61237564/how-can-i-show-days-by-group-like-whatsapp-chat-screen*/
  newGroupedDays(msgs) {
    return msgs.reduce((acc, el, i) => {
      const messageDay = el['noTime'];
      if (acc[messageDay]) {
        const tArr = { ...acc, [messageDay]: acc[messageDay].concat([el]) };
        return tArr;
      }
      const tArr = { ...acc, [messageDay]: [el] };
      return tArr;
    }, {});
  }
  
  newGenerateItems(msgs) {
    const days = this.newGroupedDays(msgs);
    console.log('%c newGroupedDays()', "background: green; color: #bada55", days);
    const sortedDays = Object.keys(days).sort(
      (x: any, y: any) => y['unixTime'] - x['unixTime']
    );
    console.log('%c sortedDays ', "background: green; color: #bada55", sortedDays);
    const items = sortedDays.reduce((acc, date) => {
      const sortedMsgs = days[date];
      return acc.concat([{ type: 'day', date, id: date }, ...sortedMsgs]);
    }, []);
    console.log('newGroupedByUsernamePosts :: ', this.newGroupedByUsernamePosts(items));
    return items;
  }

  newGroupedByUsernamePosts(sortedPosts) {
    let dataByUser = {};

    //Iterate over your data
    for (let i = 0; i < sortedPosts.length; i++) {
      if(sortedPosts[i].hasOwnProperty('type') && (sortedPosts[i].type==='day')){
        continue;
      }
      //If the object doesn't have a key equal to the current objects username, 
      //add it as a new key and set the values to a new array containing this datum.
      if (!dataByUser.hasOwnProperty(sortedPosts[i].name)) {
        dataByUser[sortedPosts[i].name] = new Array(sortedPosts[i]);
      } else {
        //If it does exist, push to the array for the existing key.
        dataByUser[sortedPosts[i].name].push(sortedPosts[i]);
      }
    }
    return dataByUser;
  }

  customAddNewMsgsAtOldMsgListEnd(newMsgArr, oldMsgList) {
    for (let i = 0; i < newMsgArr.length; i++) {}
  }

  /**
   * Show user profile
   */
  // tslint:disable-next-line: typedef
  showUserProfile() {
    document.getElementById('profile-detail').style.display = 'block';
  }

  /**
   * Close user chat
   */
  // tslint:disable-next-line: typedef
  closeUserChat() {
    document.getElementById('chat-room').classList.remove('user-chat-show');
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else if (environment.defaultauth === 'fackbackend') {
      this.authFackservice.logout();
    }
    this.router.navigate(['/account/login']);
  }

  /**
   * Set language
   * @param lang language
   */
  setLanguage(lang) {
    this.translate.use(lang);
    this.lang = lang;
  }

  openCallModal(content) {
    this.modalService.open(content, { centered: true });
  }


  openVideoModal(content) {
    this.modalService.open(content, { centered: true });
  }

  keyUpMainFunction(evt) {
    this.sendTyping();
    this.isWebview().then(value => {
      const checkWebView = value;
      console.log('%c checkWebView: ', "background: Yellow; color: #bada55", checkWebView);
      if (!checkWebView) {//only allowed in browsers
        if (evt.keyCode === 13) {
          this.sendMessage();
        }
      }
    });
  }
  
  isWebview(): Promise<boolean> {
    const windowObj = window as any;
    if (windowObj.vuplex) {
      console.log('%c Running in 3D WebView 0: ', "background: purple; color: #bada55");
      return Promise.resolve(true);
    }
    if (!windowObj._isRunningInWebViewResult) {
      windowObj._isRunningInWebViewResult = new Promise(resolve => {
        const waitThenCheckForWebView = () => {
          // Wait a second to give 3D WebView time to inject the windowObj.vuplex object.
          setTimeout(() => resolve(!!windowObj.vuplex), 1000);
        };
        if (document.readyState !== 'loading') {
          console.log('%c Running in 3D WebView 1: ', "background: purple; color: #bada55");
          waitThenCheckForWebView()
        } else {
          console.log('%c Running in 3D WebView 2: ', "background: purple; color: #bada55");
          document.addEventListener('DOMContentLoaded', waitThenCheckForWebView);
        }
      });
    }
    console.log('%c Running in 3D WebView Final: ', "background: purple; color: #bada55", windowObj._isRunningInWebViewResult);
    return windowObj._isRunningInWebViewResult;
  }

  sendMessage() {
    if((this.newMessage===undefined)||!(this.newMessage.trim())){
      // if(this.isWebview()) {// ignore msg empty check for webview
      //   return;
      // }
      
      // this.showDanger(`Message can't be blank/empty!!`);
      return
    }
    if(this.userType) { // only for group messaging as of now
      this.chatService.sendMessage(this.newMessage.trim(), this.userType);
    } else {
      this.chatService.sendMessage(this.newMessage.trim());
    }
    let tempA = this.dataService.currentUserObj.uname;
    console.log('tempA :: ', tempA);
    const today = new Date();
    const tday = today.toISOString();
    if(this.fromUIDChatHeadInfo && (this.fromUIDChatHeadInfo.hasOwnProperty('name'))) {
      this.messageList.push({/* This will only be pushed to ur screen only */
        id:this.dataService.currentFromUID,
        name: this.capitalizeFirstLetter(tempA),
        message: this.newMessage.trim(),
        align: 'right',
        isRead:false,
        unixTime:this.getUnixTimeFromIsoTime(tday),
        time:tday,
        isToday:true,// since current send happens today only
        onlyTime:this.isoTimeTransform(tday),
        dateTime: this.isoTimeTransform(tday, false, true),
        noTime:this.isoTimeTransform(tday, true)
      });
      this.groupMsgsByNameAndDateAlt(this.messageList);
      // this.directiveScroll.directiveRef.update();
      this.cdref.detectChanges();
      this.animateScroll(this.messageList.length-1);// we call auto-scroll whenever we add msg
      
      // this.chatService.dummyEvent();
    }
    this.newMessage = '';
  }

  deleteAllMsg(isDeleteAll: boolean, elemData?: any, indx?:any) {
    // return;
    console.log('%c deleteMsg() elemData', "background: red; color: #bada55", elemData);
    let postData;
    if(isDeleteAll) {
      postData = {
        userId: this.currentFromUID,
        toUID:this.fromUIDChatHeadInfo.toUID,
        operation:'deleteAll',
        role:this.loggedInUserRole,
        ...(this.userType && {type:this.userType})
      }
    } else {
      postData = {
        userId: this.currentFromUID,
        operation: 'delete',
        msgId:elemData.msgId?elemData.msgId:'',
        role:this.loggedInUserRole,
        ...(this.lastMessageId && (this.lastMessageId===elemData.msgId) && {lastMsgId:this.lastMessageId}), /* for notifying msg deleted is last msg for user */
        ...(this.userType && {type:this.userType})
      }

      if(this.jumpedToSpecificChat && !postData.hasOwnProperty('lastMsgId')){ // only when we ave jumped to a specific chat from outside iframe
        postData.lastMsgId = this.messageList[this.messageList.length-1].msgId
      }
    }
    console.log('%c deleteMsg() postData', "background: red; color: #bada55", postData);
    // return;
    this.isLoadingData = true;
    const sub = this.userService.postMsgDelete(postData).subscribe((res: any)=>{
      this.isLoadingData = false;
      if(indx || (indx === 0)) {
        this.messageList.splice(indx, 1);
        if(postData.hasOwnProperty('lastMsgId')) {
          /* We are sending for updating/refreshing userList in recent chats*/
          this.dataService.setDataToSend({
            eventName: 'YourUserId', //eventname can be anything
            data: this.currentFromUID
          });
        }
      } else {
        this.messageList.length = 0;
      }
    },
      (err: any) => {
        this.showDanger('Delete operation failed, something went wrong')
        console.log('deleteMsg [[error Status]] ::', err);
      });
    // this.visibilityStatus[index] = status;

    this.subscriptionArr.add(sub);
  }

  banUser(modalElem, elemData?: any, indx?:any) {
    // return;
    console.log('%c banUser() elemData', "background: red; color: #bada55", elemData);
		this.modalService.open(modalElem).result.then(
			(resolveResult) => {
        console.log(`Closed with: ${resolveResult}`);
        let postData;
    
        postData = {
          userId: this.currentFromUID,
          userIdBanned: elemData.id,
          msgId: elemData.msgId,
          msg:elemData.message,
          ...(this.userType && { groupId: this.fromUIDChatHeadInfo.toUID }),
          isBanned: true,
          ...(this.userType && { type: this.userType })
        }
        console.log('%c banUser() postData', "background: red; color: #bada55", postData);
        // return;
        this.isLoadingData = true;
        const sub = this.userService.postBanUser(postData).subscribe((res: any) => {
          this.isLoadingData = false;
          this.showInfo('Ban user operation successfull!!');
        },
          (err: any) => {
            this.isLoadingData = false;
            this.showDanger('Ban user operation failed, something went wrong')
            console.log('BAN-USER [[error Status]] ::', err);
          });

        this.subscriptionArr.add(sub);
			},
			(rejectReason) => {
        console.log(`Dismissed with: ${rejectReason}`);
			},
		);
    // this.visibilityStatus[index] = status;
  }

  isoTimeTransform(isoString: any, noTimeStatus?:boolean, dateTime?:boolean) {
    if(dateTime){
      return this.datePipe.transform(isoString, "dd/MMM/yyyy H:mm");
    }
    if(noTimeStatus) {
      return this.datePipe.transform(isoString, "dd MMM yyyy");
    } else {
      return this.datePipe.transform(isoString, "H:mm");
    }
  }

  getUnixTimeFromIsoTime(isoString: any) {
    let uTime = new Date(isoString).getTime();
    return uTime;
  }

  private scrollToBottom(offsetVal?: number, elem?: string): void {
    // console.log('scrollContainer :: ', this.scrollContainer, this.scrollContainer.scrollHeight); 
    const dRef = this.directiveScroll.directiveRef;
    console.log('scrollContainer :: ', dRef, dRef.position());
    console.log('scrollContainer geometry:: ', dRef.geometry());
    console.log('scrollContainer ps:: ', dRef.ps());
    const scrollContainerHeight = dRef.elementRef.nativeElement.clientHeight;
    const geometryData = dRef.geometry();
    // if (isUndefined(this.directiveScroll)) return;
    if(elem){
      // const elem = document.querySelector('.msgListItem');
      // dRef.scrollToElement(elem,offsetVal, 1000);
      console.log('scrollToElement :: ', elem);
      // this.cdref.detectChanges();
      // dRef.scrollToElement('li:nth-child(5)', 0, 1000);
      // dRef.scrollToTop(150, 1000);
      dRef.scrollToBottom(0, 1000);
    } else if(offsetVal){
      // const elem = document.querySelector('.msgListItem');
      // dRef.scrollToElement(elem,offsetVal, 1000);
      dRef.scrollToBottom(offsetVal, 1000);
    } else {
      dRef.scrollToBottom(0, 1000);
    }

    /*
    update() :- Updates the scrollbar size & position.(It may be required when we 
    implement infinite scrolling)
    */
    // dRef.update();

  }

  altJumpToBottom = () => {/* Whatsapp like direct jump to bottom without scrolling */
    try {
      console.log('<< this.infScrollElement.nativeElement in altJumpToBottom() >> :: ', this.scrollContainer);
      this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
    } catch (err) {}
  }
  
  animateScroll(indx?:number) {
    if(indx){
      console.log('<< this.infScrollElement.nativeElement >> :: ', this.scrollContainer.offsetHeight);
      console.log('this.itemElementsForScroll :: ', this.itemElementsForScroll.get(indx), indx);
      const el2 = this.renderer.selectRootElement(this.itemElementsForScroll.get(indx).nativeElement , true);
      el2.scrollIntoView({behavior: 'smooth'});
    } 
    else {
      console.log('%c animateScroll()', "background: green; color: #bada55", this.scrollFrame);
      const el2 = this.renderer.selectRootElement(this.scrollFrame.nativeElement , true);
      
      setTimeout(() => {
        el2.scrollTo({
          bottom: 800,
          left: 100,
          behavior: 'smooth'
        }); 
      }, 2000);
      // this.cdref.detectChanges();
    }
  }
  
  onListUpScroll(event: any): void {
    console.log('In onListUpScroll() :: ', event);
    if (this.isLoadingData || (this.stopScrollCheck)) {
      console.log('%c RETURN In onListScrollEvent()', "background: red; color: #bada55");
      // event.preventDefault();
      this.scrollContainer.scrollTo(0, this.scrollToSomePercent * this.scrollContainer.scrollHeight);
      return;
    }
    // if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    //   // console.log('In onListScroll() :: ', event);
    // }
    if(this.srchHelperDetails && this.srchHelperDetails.hasOwnProperty('firstDocumentNumberForPrev') && this.srchHelperDetails['firstDocumentNumberForPrev']) {
      this.dataService.setDataToSend({
        eventName: 'GetMessages', //eventname can be anything
        data: { fromUID: this.dataService.currentFromUID, toUID: this.fromUIDChatHeadInfo['toUID'],
          fIndex: this.srchHelperDetails['firstDocumentNumberForPrev'],
          direction:'prev',
          ...(this.userType && {type:this.userType}) // only for group for now
        }
      });
      this.srchDirection = 'prev';
      this.scrollContainer.scrollTo(0, this.scrollToSomePercent * this.scrollContainer.scrollHeight);
      // this.renderer.setStyle(this.scrollContainer, 'overflow', 'hidden');
      this.cdref.detectChanges();
    } else if(this.fromUIDChatHeadInfo['index']) {
      this.dataService.setDataToSend({
        eventName: 'GetMessages', //eventname can be anything
        data: { fromUID: this.dataService.currentFromUID, toUID: this.fromUIDChatHeadInfo['toUID'],
          fIndex: this.fromUIDChatHeadInfo['index'],
          ...(this.userType && {type:this.userType}) // only for group for now
        }
      });
      this.scrollContainer.scrollTo(0, this.scrollToSomePercent * this.scrollContainer.scrollHeight);
      // this.renderer.setStyle(this.scrollContainer, 'overflow', 'hidden');
      this.cdref.detectChanges();
    } else {
      // this.showInfo('No more data available');
      return;
    }
    this.stopScrollCheck = true;
    this.scrollUpEvent = true; 
  }
  
  onListDownScroll(event: any): void {
    console.log('In onListDownScroll() :: ', event);
    if (this.isLoadingData || (this.stopScrollCheck) || !this.srchTurnedOn) {
      console.log('%c RETURN In onListDownScrollEvent()', "background: green; color: #bada55");
      // event.preventDefault();
      // this.scrollContainer.scrollTo(0, (1-this.scrollToSomePercent) * this.scrollContainer.scrollHeight);
      return;
    }
    // if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    //   // console.log('In onListScroll() :: ', event);
    // }
    if(this.srchHelperDetails && this.srchHelperDetails.hasOwnProperty('lastDocumentNumberForNext') && this.srchHelperDetails['lastDocumentNumberForNext']) {
      this.dataService.setDataToSend({
        eventName: 'GetMessages', //eventname can be anything
        data: { fromUID: this.dataService.currentFromUID, toUID: this.fromUIDChatHeadInfo['toUID'],
          fIndex: this.srchHelperDetails['lastDocumentNumberForNext'],
          direction:'next',
          ...(this.userType && {type:this.userType}) // only for group for now
        }
      });
      this.srchDirection = 'next';
      // this.scrollContainer.scrollTop = (this.scrollToSomePercent) * this.scrollContainer.scrollHeight;
      // this.scrollContainer.scrollTo(0, (1-this.scrollToSomePercent) * this.scrollContainer.scrollHeight);
      // this.cdref.detectChanges();
      // this.renderer.setStyle(this.scrollContainer, 'overflow', 'hidden');
    } else {
      if(this.scrollDownApiHitThreshold===0){/* For not showing more than once-"No more data available" msg*/
        return;
      }
      // this.showInfo('No more data available');
      return;
    }
    this.stopScrollCheck = true;
    this.scrollUpEvent = true; 
  }

  validateMimeCheckAndRead(fileObject, fType, languageFormIndex) {
    // const langForm = <FormArray>this.languagePolicyForm.get('languageItems');

    const errMessage = {};
    errMessage['type'] = 'policy'; // to indicate to snackbar that this is policy based error
    // errMessage['belowTitleInfo'] = this.getBelowTitleInfo(languageFormIndex);
    errMessage['errorAction'] = 'load file';
    errMessage['messageMajor'] = 'The file failed to be loaded due to the following error.';
    if (fileObject.size > 10214400) { // 25*1024kb*1024bytes(Following windows file system)-> 25miB pdf file limit in decimal
      errMessage['messageMinor'] = 'File size exceeds 10MB.';
      this.showDanger('File size exceeds 10MB.');
      return;
    }
    if(!fileTypesAllowedCollection.includes(fileObject.type)){
      this.showDanger('Unsupported file uploaded!!.');
      return;
    }

    let tempA = this.dataService.currentUserObj.uname;
    const today = new Date();
    const tday = today.toISOString();
    if(this.fromUIDChatHeadInfo && (this.fromUIDChatHeadInfo.hasOwnProperty('name'))) {
      this.messageList.push({/* This will only be pushed to ur screen only */
        id:this.dataService.currentFromUID,
        name: this.capitalizeFirstLetter(tempA),
        message: '',
        // msgId:respData.msgId,
        align: 'right',
        isRead: false,
        isFile: true, // right now for all file type
        unixTime:this.getUnixTimeFromIsoTime(tday),
        time:tday,
        isToday:true,// since current send happens today only
        onlyTime:this.isoTimeTransform(tday),
        dateTime: this.isoTimeTransform(tday, false, true),
        noTime:this.isoTimeTransform(tday, true),
        fileSize:this.customFormatBytes(fileObject.size),
        fileName:fileObject.name,
      });
      this.groupMsgsByNameAndDateAlt(this.messageList);
      // this.directiveScroll.directiveRef.update();
      this.cdref.detectChanges();
      this.animateScroll(this.messageList.length-1);// we call auto-scroll whenever we add msg
      
      // this.chatService.dummyEvent();
    }
    
    const postData = new FormData();
    if (this.userType) { // only for group messaging as of now
      // this.chatService.sendMessage(fileObject, this.userType, true);
    postData.append('type', this.userType);
    }
    postData.append('file', fileObject);
    postData.append('isRead', 'false');
    postData.append('isFile', 'true');
    postData.append('toName', this.capitalizeFirstLetter(this.fromUIDChatHeadInfo.name));
    postData.append('fromName', this.capitalizeFirstLetter(this.dataService.currentUserObj.uname));
    postData.append('fromUID', this.currentFromUID);
    postData.append('toUID', this.fromUIDChatHeadInfo.toUID);
    const sub = this.userService.postUploadFile(postData).subscribe((res:any)=>{
      
      console.log('Inside postUploadFile() :: ', res);
      if (res.type === HttpEventType.UploadProgress) {
        // This is an upload progress event. Compute and show the % done:
        const percentDone = Math.round(100 * res.loaded / res.total);
        console.log(`File is ${percentDone}% uploaded.`);
        this.messageList[this.messageList.length-1].uploadPercent = percentDone;
        if(percentDone===100){
          this.messageList[this.messageList.length-1].uploadPercent = 0;  
        }
      }
      if (res.type === HttpEventType.Response) {
          console.log("Upload completed", res);
          const respData = res.body.data;
          this.messageList[this.messageList.length-1].fileContent = res.body.data.msg;
          this.messageList[this.messageList.length-1].msgId = res.body.data.msgId;
          this.chatService.notifyAttachmentSent(res.body.data);
      }
      
    }, err =>{
      this.showDanger('Something went wrong :: '+ err);
    });

    this.subscriptionArr.add(sub);

    // for reading file as array buffer for checking the actual mime of file being uploaded
    const readerMimeCheck = new FileReader();

    // for reading file as base64 encoded result for displaying in src attribute of element
    const readerBase64 = new FileReader();

    readerMimeCheck.onload = (e: any) => {
      console.log('reader.result: ', readerMimeCheck.result);
      // if (e.target.readyState === FileReader.DONE) {
      const uint = new Uint8Array(e.target.result); console.log('e.target.result: ', e.target.result, ' uint: ', uint);
      const bytes = [];
      uint.forEach((byte) => {
        bytes.push(byte.toString(16))
      });
      readerBase64.readAsDataURL(fileObject);
      // }
    };
    // reader.readAsDataURL(this.selectedFile);
    readerMimeCheck.readAsArrayBuffer(fileObject.slice(0, 4));

    readerBase64.onload = (e: any) => {
      console.log('inside onFileChanged, reader.onload event :: ', e);

      if (fType === 'image') {

        // this.checkPImageDimensions(e, fileObject, languageFormIndex);
      } else {

        // if (langForm.controls[languageFormIndex].get('pdfName').value) {
        //   langForm.controls[languageFormIndex].get('pdfName').setValue(null);
        // }

        // this.pdfFile = fileObject;
        // langForm.controls[languageFormIndex].get('pdf').setValue(fileObject);

        // setting name in HTML file input part
        const fName = fileObject.name;
        const fileName = fName;
        // langForm.controls[languageFormIndex].get('pdfName').setValue(fileName);
      }
    };
  }

  onFileChanged(event, fType, languageFormIndex) {
    console.log('Inside onFileChanged :: ', event);

    if (event.target.files[0]) {
      const selectedFile = event.target.files[0];

      this.validateMimeCheckAndRead(selectedFile, fType, languageFormIndex);
    }
  }

  uploadFile(elem, index, removefile?: boolean) {
      elem.value = ''; // for being able to upload same file again
      if (removefile) { // in case you want to remove pdf file
          // const langForm = <FormArray>this.languagePolicyForm.get('languageItems');
          // langForm.controls[index].get('pdfName').setValue('');
          // langForm.controls[index].get('pdf').setValue(null);

          return;
      }
      elem.click();

  }
  
  customFormatBytes(bytes, decimals = 2) {/* convert size in bytes to KB, MB, GB */
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }
  
  downloadFile(url, fileNameVal) {
    this.showInfo('Download starting...');
    const a = document.createElement('a');
    a.href = url;
    a.download = fileNameVal;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

	openSrchOffCanvas(listContent) {
		this.offcanvasService.open(listContent, { position: 'end' }).result.then(
      (resolveResult) => {
        console.log(`Closed OffCanvas with: ${resolveResult}`);
        this.clearUserChatSearch();
      },
      (rejectReason) => {
        console.log(`Dismissed OffCanvas with: ${rejectReason}`);
        if(rejectReason==='Cross click'){}
        this.clearUserChatSearch();
      },
    )
	}

	// private getDismissReason(reason: any): string {
	// 	if (reason === OffcanvasDismissReasons.ESC) {
	// 		return 'by pressing ESC';
	// 	} else if (reason === OffcanvasDismissReasons.BACKDROP_CLICK) {
	// 		return 'by clicking on the backdrop';
	// 	} else {
	// 		return `with: ${reason}`;
	// 	}
	// }
  
  usrChatSrchResultJump(itemData) {
    const postData = {
      fromUID: itemData.fromUID,
      toUID: itemData.toUID,
      documentNumber: itemData.documentNumber
    }

    this.srchTurnedOn = true;
    this.jumpToSearchedMsgId = itemData.msgId;
    this.messageList.length = 0;
    this.dataService.setDataToSend({
      eventName: 'GetMessages', //eventname can be anything
      data: { fromUID: this.dataService.currentFromUID, toUID: this.fromUIDChatHeadInfo['toUID'],
      documentNumber: itemData.documentNumber,
        ...(this.userType && {type:this.userType}) // only for group for now
      }
    });

    /*Closing off canvas on selecting option */
    this.offcanvasService.dismiss('Option selected');

    
    // this.isLoadingData = true;
    // const sub = this.userService.getInitialMessages(postData).subscribe((resp: any) => {
    //   this.isLoadingData = false;
    //   console.log('resp in usrChatSrchResultJump:: ', resp);
    //   this.messageList = resp.data;
    //   // // let tempArr = resp.map((elem: any)=> {
    //   // const tday = new Date();
    //   // const todayIsoString = tday.toISOString();
    //   // console.log('tday in getSearchInRecentChatsList:: ', todayIsoString, ', ', tday.getDate(), ', ', tday.getDay());
    //   // this.chatArr.forEach(elem => {
    //   //   if (elem.hasOwnProperty('msgUpdatedAt') && elem.msgUpdatedAt) {
    //   //     elem.time = this.getLastMsgDisplayTime(tday, todayIsoString, elem.msgUpdatedAt);
    //   //   }
    //   // });
    // },
    // (err: any) => {
    //   this.showLoader = false;
    // //   localStorage.clear();
    //   console.log('error Status', err.status);
    //   this.showDanger('Something went wrong :: '+ err);
    //   // if (err.status === 401) { // When unauthenticated access due to wrong token or session expire
    //   //   this.saveRedirectUrlAndLogoutToMainPage();
    //   // } else { // When unauthenticated access due to reasons like partner info not available, server down
    //   //   this.userService.logout();
    //   // }
    // });

    // this.subscriptionArr.add(sub);
  }
  
  getDataFromSearchAPI(trimmmedQryStr: string) {
    if(!trimmmedQryStr){
      this.usrChatSrchList.length = 0;
      return;
    }
    const postData = {
      fromId: this.currentFromUID,
      toId: this.fromUIDChatHeadInfo.toUID,
      txt: trimmmedQryStr,
      ...(this.userType && {type:this.userType}) // only for group for now
    }

    
    this.isLoadingSrchData = true;
    const sub = this.userService.getSearchInMessagesList(postData).subscribe((resp: any) => {
      this.isLoadingSrchData = false;
      console.log('resp in getSearchInMessagesList:: ', resp);
      const today = new Date();
      const tday = today.toISOString();
      this.usrChatSrchList = resp.data.map((elem: any)=> {
        // const today = new Date();
        // const tday = today.toISOString();
        // console.log('date now', today.getDate());
        elem['onlyTime'] = this.isoTimeTransform(elem['createdAt']);
        elem['dateTime'] = this.isoTimeTransform(elem['createdAt'], false, true);
        elem['noTime'] = this.isoTimeTransform(elem['createdAt'], true);
        elem['isToday'] = (this.isoTimeTransform(elem['createdAt'], true)===this.isoTimeTransform(tday, true));
        // elem['hideName'] = false;
        // elem.name = this.capitalizeFirstLetter(elem.name);
        if(elem.fileSize) {
          elem.fileSize = this.customFormatBytes(elem.fileSize);
          // elem.fileContent = elem.message;
        }
        return elem;
      });
      // // let tempArr = resp.map((elem: any)=> {
      // const tday = new Date();
      // const todayIsoString = tday.toISOString();
      // console.log('tday in getSearchInRecentChatsList:: ', todayIsoString, ', ', tday.getDate(), ', ', tday.getDay());
      // this.chatArr.forEach(elem => {
      //   if (elem.hasOwnProperty('msgUpdatedAt') && elem.msgUpdatedAt) {
      //     elem.time = this.getLastMsgDisplayTime(tday, todayIsoString, elem.msgUpdatedAt);
      //   }
      // });
    },
    (err: any) => {
      this.isLoadingSrchData = false;
    //   localStorage.clear();
      console.log('error Status', err.status);
      this.showDanger('Something went wrong :: '+ err);
      // if (err.status === 401) { // When unauthenticated access due to wrong token or session expire
      //   this.saveRedirectUrlAndLogoutToMainPage();
      // } else { // When unauthenticated access due to reasons like partner info not available, server down
      //   this.userService.logout();
      // }
    });

    this.subscriptionArr.add(sub);

  }
  
  onChatSrchQryChange(query:any) {
    this.chatSrchQueryChanged.next(query);
  }

  clearUserChatSearch() {
    this.usrChatSrchQuery = '';
    this.usrChatSrchList.length = 0;
    // this.chatService.refreshUserList(false);
  }

  sendTyping() {
    /* Refer link for info about events used - 
    https://stackoverflow.com/questions/38502560/whats-the-difference-between-keyup-keydown-keypress-and-input-events */
    const postData = {
      fromUID: this.currentFromUID,
      toUID: this.fromUIDChatHeadInfo.toUID,
      fromName:this.dataService.currentUserObj.uname,
      ...(this.userType && {type:this.userType}) // only for group for now
    }
    this.chatService.notifyTyping(postData);
  }

  ngOnDestroy(): void {
    console.log('list of subscriptions before unsubscribing inside ngOnDestroy: ', this.subscriptionArr);
    if (this.tabVisibilitylistener){ // this removes listener
      this.tabVisibilitylistener(); this.tabVisibilitylistener = null;
    }
    this.subscriptionArr.unsubscribe();
  }
}
