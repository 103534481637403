export const environment = {
  production: true,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },

  USERS_VERIFY_N_CREATE: '/api/v1/users/verify',
  USERS_RECENT_LIST:'/api/v1/users/list',
  USERS_ALL_LIST:'/api/v1/users',
  USERS_MESSAGES:'/api/v1/messages',
  USERS_MESSAGES_READ_UPDATE:'/api/v1/messages/update',
  USERS_MESSAGES_DELETE:'/api/v1/messages/delete',
  USERS_LIST_SEARCH:'/api/v1/users/search',
  USERS_SETTING:'/api/v1/users/setting',
  FILE_UPLOAD:'/api/v1/users/attachments',
  MESSAGES_SEARCH:'/api/v1/messages/search'
};
