import { Injectable } from "@angular/core";

@Injectable()

export class Configurations {
    private _baseUrl;
    private _hostName;
    private _partnerId;
    private _partnerName;

    constructor() {
        this._hostName = window.location.hostname;

        if (this._hostName === 'localhost' || this._hostName === '127.0.0.1') {
            this._baseUrl = 'https://tunnel.kapadiya.net';
            // this._baseUrl = 'http://192.168.100.100:3000'; // with light at praddumn
            // this._baseUrl = 'http://192.168.43.163:3000'; // without light on mobile hotspot

            /* Only When work from home testing */
            // this._baseUrl = 'http://localhost:3000'; //our local node backend
            /* Only When work from home testing */

        } else if (this._hostName === '192.168.1.135') {

            this._baseUrl = 'http://192.168.43.163:3000';
        } else {
            this._baseUrl = `https://api-chat.exposim.io`;
            /*START: Only Local ngrok based socket tessting */
            // this._baseUrl = 'https://1a05-2401-4900-5aa1-fd49-2df4-64a9-c01b-c799.ngrok.io';
            /*END: Only Local ngrok based socket testing */
        }
    }

    get baseUrl() {
        return this._baseUrl = this._baseUrl ? this._baseUrl : window.location.origin;
    }
}
