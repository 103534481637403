import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
export type UserObj = {
  uid: string;
  uname: string;
  role: string;
  isNewUser?: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class InterComponentDataService {
  private currentFUID: string;
  private currentReloadFlag: boolean;
  private $data = new Subject<any>();
  public dataReceivedEvent = this.$data.asObservable();
  public currentUserObj:UserObj;
  public currentJumpToChatObj:any;

  constructor() {
  }

  public setCurrentUserValue(data: UserObj) {
      this.currentUserObj = JSON.parse(JSON.stringify(data));
  }

  public setJumpToChatData(data: any) {
      this.currentJumpToChatObj = JSON.parse(JSON.stringify(data));
  }

  public getCurrentJumpToChatData() {
    return this.currentJumpToChatObj;
  }

  // convenience getter for easy access
  get currentFromUID() { return this.currentFUID || this.currentUserObj.uid; }

  // convenience getter for easy access
  get currentPageReloadStatus() { return this.currentReloadFlag; }

  public setDataToSend(resdata: any): void {
    if (resdata.eventName === 'YourUserId') {
      this.currentFUID = resdata.data;
    } else if (resdata.eventName === 'PageRefresh') {
      this.currentReloadFlag = resdata.data;
    } //and so on
    this.$data.next(resdata);
  }
}
