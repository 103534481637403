import { Directive, ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {fromIntersectionObserver, IntersectionStatus} from './from-intersection-observer';

@Directive({
  selector: '[intersectionObserver]'
})
export class IntersectionObserverDirective implements OnInit, OnDestroy {
  /* See Ref - https://blog.bitsrc.io/angular-maximizing-performance-with-the-intersection-observer-api-23d81312f178 */
  @Input() intersectionDebounce = 0;
  @Input() intersectionRootMargin = '0px';
  @Input() intersectionRoot: HTMLElement;
  @Input() intersectionDisable: boolean;
  @Input() intersectionThreshold: number | number[];

  @Output() visibilityChange = new EventEmitter<IntersectionStatus>();

  private destroy$ = new Subject();

  constructor(private element: ElementRef) {}

  ngOnInit() {
    if(this.intersectionDisable) {
      console.log('intersectionDisable is disabled');
      return;
    }
    const element = this.element.nativeElement;
    const config = {
      root: this.intersectionRoot,   // default is the viewport
      rootMargin: this.intersectionRootMargin,
      threshold: this.intersectionThreshold // percentage of target's visible area (.5 means 50%). Triggers "onIntersection"
    };

    fromIntersectionObserver(
      element,
      config,
      this.intersectionDebounce
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe((status) => {
      this.visibilityChange.emit(status);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}