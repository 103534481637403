<!-- Start chats content -->
<div class="position-relative d-block h-100">
  <div class="px-4 pt-4">
    <h4 class="mb-4">
      <span [customTooltip]="currentFromName">{{'chat.tabs.chats.title' | translate}}</span>
    </h4>
    <div class="search-box chat-search-box">
      <div class="input-group mb-3 rounded-3">
        <span class="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon1">
          <i class="ri-search-line search-icon font-size-18"></i>
        </span>
        <input type="text" class="form-control bg-light" placeholder="{{'chat.tabs.chats.search.placeholder' | translate}}"
          aria-label="Search users" aria-describedby="basic-addon1" [(ngModel)]="usrSrchQuery"
          (ngModelChange)="onQryChange($event)">
        <span class="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon2">
          <i *ngIf="usrSrchQuery && (usrSrchQuery.trim().length!==0)" class="ri-close-line close-icon font-size-18" (click)="clearUserSearch()"></i>
        </span>
      </div>
    </div>
  </div> <!-- .p-4 -->

  <!-- Start user status -->
  <div class="px-4 pb-4 d-none" dir="ltr">
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <div class="item">
          <a href="#" class="user-status-box">
            <div class="avatar-xs mx-auto d-block chat-user-img online">
              <img src="assets/images/users/avatar-2.jpg" alt="user-img" class="img-fluid rounded-circle">
              <span class="user-status"></span>
            </div>

            <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.1' | translate}}</h5>
          </a>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="item">
          <a href="#" class="user-status-box">
            <div class="avatar-xs mx-auto d-block chat-user-img online">
              <img src="assets/images/users/avatar-4.jpg" alt="user-img" class="img-fluid rounded-circle">
              <span class="user-status"></span>
            </div>

            <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.2' | translate}}</h5>
          </a>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="item">
          <a href="#" class="user-status-box">
            <div class="avatar-xs mx-auto d-block chat-user-img online">
              <img src="assets/images/users/avatar-5.jpg" alt="user-img" class="img-fluid rounded-circle">
              <span class="user-status"></span>
            </div>

            <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.3' | translate}}</h5>
          </a>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="item">
          <a href="#" class="user-status-box">
            <div class="avatar-xs mx-auto d-block chat-user-img online">
              <img src="assets/images/users/avatar-6.jpg" alt="user-img" class="img-fluid rounded-circle">
              <span class="user-status"></span>
            </div>

            <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.4' | translate}}</h5>
          </a>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="item">
          <a href="#" class="user-status-box">
            <div class="avatar-xs mx-auto d-block chat-user-img online">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                T
              </span>
              <span class="user-status"></span>
            </div>

            <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.5' | translate}}</h5>
          </a>
        </div>
      </ng-template>

    </owl-carousel-o>

    <!-- end user status carousel -->
  </div>
  <!-- end user status -->

  <!-- Start chat-message-list-->
  <div class="px-2">
    <h5 class="mb-3 px-3 font-size-16 d-none">{{'chat.tabs.chats.recent.title' | translate}}</h5>

    <perfect-scrollbar class="chat-message-list">

      <ul class="list-unstyled chat-list chat-user-list" *ngIf="chatArr">
        <li *ngFor="let user of chatArr" [ngClass]="{'typing': user.isTyping, 'active': user.isActive}"
          (click)="user.type?showChat(user.id, user.name, user.lastMsgId, user.type):showChat(user.id, user.name, user.lastMsgId)">
          <a href="#">
            <div class="d-flex">
              <div class="chat-user-img align-self-center me-3 ms-0" [ngClass]="{'online': user.status === 'online',
               'away': user.status === 'away'}">
                <img *ngIf="user.profilePicture" src="{{user.profilePicture}}" class="rounded-circle avatar-xs" alt="">
                <img *ngIf="!user.profilePicture && user.type" src="assets/images/group/group.png" class="rounded-circle avatar-xs" alt="">
                <div class="avatar-xs" *ngIf="!user.profilePicture && !user.type">
                  <span class="avatar-title rounded-circle custom-bg-blue">
                    {{user.name ? ((user.name | translate).charAt(0)):'Error'}}
                  </span>
                </div>
                <span *ngIf="user.status" class="user-status"></span>
              </div>

              <div class="flex-1 overflow-hidden">
                <h5 class="text-truncate font-size-15 mb-1">{{user.name | translate}}</h5>
                <p *ngIf="user.isTyping" class="chat-user-message text-truncate d-flex justify-content-start mb-0">
                  <ng-container *ngIf="user.typerName">
                    <span class="text-truncate custom-max-width-50">{{user.typerName}}</span><span style="width: 1ch;"></span><span>is</span><span style="width: 1ch;"></span>
                  </ng-container>
                  <span>typing</span>
                  <span class="animate-typing">
                    <span class="dot ms-1"></span>
                    <span class="dot ms-1"></span>
                    <span class="dot ms-1"></span>
                  </span>
                </p>
                <p *ngIf="!user.isTyping" class="chat-user-message text-truncate mb-0">{{user.lastMessage | translate}}</p>
              </div>
              <div class="font-size-11">{{user.time | translate}}</div>
              <div class="unread-message">
                <span class="badge badge-soft-danger rounded-pill">{{user.unRead?user.unRead:''}}</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </perfect-scrollbar>

  </div>
  <!-- End chat-message-list -->
    
  <app-ui-loader class="d-block" *ngIf="showLoader1"
  style="margin-top: 50%;" [transparentBackground]="true"></app-ui-loader>
</div>
<!-- Start chats content -->