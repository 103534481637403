import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Configurations } from './configurations.service';

@Injectable({
  providedIn: 'root'
})
export class UserVerifyService {
  private configuration;

  constructor(private http: HttpClient, private config: Configurations) { 
    this.configuration = config;
  }

  // postLoginData(data) {
  //   let headers = new HttpHeaders(); // since HttpHeaders is immutable
  //   headers = headers.append('Content-Type', 'application/json');
  //   // headers = headers.append('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('connectManagerToken')));
  //   // console.log('JSON TOKEN :: ', JSON.parse(localStorage.getItem('connectManagerToken')));

  //   const url = this.configuration.baseUrl + environment.DELOITTE_LOGIN;
  //   // const url = 'https://demourl.free.beeceptor.com/userLogin';

  //   console.log('url for login: ', url);

  //   return this.http.post(url, JSON.stringify(data), {headers});
  // }

  // hitSAMLSSOLogin() {
  //   let headers = new HttpHeaders(); // since HttpHeaders is immutable
  //   headers = headers.append('Content-Type', 'application/json');
  //   // headers = headers.append('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('connectManagerToken')));
  //   // console.log('JSON TOKEN :: ', JSON.parse(localStorage.getItem('connectManagerToken')));

  //   const url = this.configuration.baseUrl + environment.SAML_SSO_LOGIN;
  //   // const url = 'https://demourl.free.beeceptor.com/userLogin';

  //   console.log('url for SAML SSO login: ', url);

  //   return url;
  //   // return this.http.post(url, JSON.stringify(data), {headers});
  // }

  checkUserVerification(data: any) {
      // console.log('token inside checkJWTTokenSession :: ', token);
      let headers = new HttpHeaders(); // since HttpHeaders is immutable
      headers = headers.append('Content-Type', 'application/json');
      // headers = headers.append('Authorization', 'Bearer ' + token);

      const url = this.config.baseUrl + environment.USERS_VERIFY_N_CREATE + `?role=${data.role}&userId=${data.userId}&userName=${data.userName}&eventId=${data.eventId}`;
      return this.http.get(url, {headers});
  }

  getRecentUserList(data: any) {
      // console.log('token inside checkJWTTokenSession :: ', token);
      let headers = new HttpHeaders(); // since HttpHeaders is immutable
      headers = headers.append('Content-Type', 'application/json');
      // headers = headers.append('Authorization', 'Bearer ' + token);

      const url = this.config.baseUrl + environment.USERS_RECENT_LIST + `?userId=${data.userId}`;
      return this.http.get(url, {headers});
  }

  getContactsList(data: any) {
      // console.log('token inside checkJWTTokenSession :: ', token);
      let headers = new HttpHeaders(); // since HttpHeaders is immutable
      headers = headers.append('Content-Type', 'application/json');
      // headers = headers.append('Authorization', 'Bearer ' + token);

      const url = this.config.baseUrl + environment.USERS_ALL_LIST + `?userId=${data.userId}&type=${data.type}`;
      return this.http.get(url, {headers});
  }

  getInitialMessages(data: any) {
      // console.log('token inside checkJWTTokenSession :: ', token);
      let headers = new HttpHeaders(); // since HttpHeaders is immutable
      headers = headers.append('Content-Type', 'application/json');
      // headers = headers.append('Authorization', 'Bearer ' + token);

      let url='';
      if(data.hasOwnProperty('type') && data.type==='group'){
        if(data.hasOwnProperty('documentNumber')){/* Only When jumping to src result msg */
          url = this.config.baseUrl + environment.USERS_MESSAGES + `?fromUID=${data.fromUID}&toUID=${data.toUID}&jumpToDocumentNumber=${data.documentNumber}&type=${data.type}`;
        } else {
          url = this.config.baseUrl + environment.USERS_MESSAGES + `?fromUID=${data.fromUID}&toUID=${data.toUID}&type=${data.type}`;
        }
      } else {
        if(data.hasOwnProperty('documentNumber')){/* Only When jumping to src result msg */
          url = this.config.baseUrl + environment.USERS_MESSAGES + `?fromUID=${data.fromUID}&toUID=${data.toUID}&jumpToDocumentNumber=${data.documentNumber}`;
        } else {
          url = this.config.baseUrl + environment.USERS_MESSAGES + `?fromUID=${data.fromUID}&toUID=${data.toUID}`;
        }
      }

      if(data.hasOwnProperty('fIndex')){ // for only when scrolling for more msg data
        url = `${url}&index=${data['fIndex']}`
        if(data.hasOwnProperty('direction')) {
          url = `${url}&direction=${data['direction']}`
        }
      }
      return this.http.get(url, {headers});
  }

  postReadUpdateData(data) {
    let headers = new HttpHeaders(); // since HttpHeaders is immutable
    headers = headers.append('Content-Type', 'application/json');
    // headers = headers.append('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('connectManagerToken')));
    // console.log('JSON TOKEN :: ', JSON.parse(localStorage.getItem('connectManagerToken')));

    const url = this.configuration.baseUrl + environment.USERS_MESSAGES_READ_UPDATE;

    return this.http.post(url, data, {headers});
  }

  postMsgDelete(data) {
    let headers = new HttpHeaders(); // since HttpHeaders is immutable
    headers = headers.append('Content-Type', 'application/json');
    // headers = headers.append('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('connectManagerToken')));
    // console.log('JSON TOKEN :: ', JSON.parse(localStorage.getItem('connectManagerToken')));

    const url = this.configuration.baseUrl + environment.USERS_MESSAGES_DELETE;

    return this.http.post(url, data, {headers});
  }

  getSearchInRecentChatsList(data: any) {
      // console.log('token inside checkJWTTokenSession :: ', token);
      let headers = new HttpHeaders(); // since HttpHeaders is immutable
      headers = headers.append('Content-Type', 'application/json');
      // headers = headers.append('Authorization', 'Bearer ' + token);

      const url = this.config.baseUrl + environment.USERS_LIST_SEARCH + `?userId=${data.userId}&searchText=${data.txt}&eventId=${data.eventId}`;
      return this.http.get(url, {headers});
  }

  postBanUser(data) {
    let headers = new HttpHeaders(); // since HttpHeaders is immutable
    headers = headers.append('Content-Type', 'application/json');
    // headers = headers.append('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('connectManagerToken')));
    // console.log('JSON TOKEN :: ', JSON.parse(localStorage.getItem('connectManagerToken')));

    const url = this.configuration.baseUrl + environment.USERS_SETTING;

    return this.http.post(url, data, {headers});
  }

  postUploadFile(formdata) {
    let headers = new HttpHeaders(); // since HttpHeaders is immutable
    // headers = headers.append('Content-Type', 'application/json');
    // headers = headers.append('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('connectManagerToken')));
    // console.log('JSON TOKEN :: ', JSON.parse(localStorage.getItem('connectManagerToken')));

    /* Added for IE11 API cache and not refecting changes Issue*/
    headers = headers.append('Content-Control', 'no-cache');
    headers = headers.append('Pragma', 'no-cache');

    const url = this.configuration.baseUrl + environment.FILE_UPLOAD;

    return this.http.post(url, formdata, {reportProgress: true, observe: "events", headers: headers});
  }

  getSearchInMessagesList(data: any) {
      // console.log('token inside checkJWTTokenSession :: ', token);
      let headers = new HttpHeaders(); // since HttpHeaders is immutable
      headers = headers.append('Content-Type', 'application/json');
      // headers = headers.append('Authorization', 'Bearer ' + token);

      let url;
      if(data.hasOwnProperty('type')){/* only for group */
        url = this.config.baseUrl + environment.MESSAGES_SEARCH + `?fromUID=${data.fromId}&toUID=${data.toId}&type=${data.type}&searchText=${data.txt}`;
      } else {
        url = this.config.baseUrl + environment.MESSAGES_SEARCH + `?fromUID=${data.fromId}&toUID=${data.toId}&searchText=${data.txt}`;
      }
      return this.http.get(url, {headers});
  }

}
