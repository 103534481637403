<div class="layout-wrapper d-lg-flex">
  <div class="side-menu flex-lg-column me-lg-1" *ngIf="!hideSideMenuForNow">
    <!-- LOGO -->
    <div class="navbar-brand-box">
      <a routerLink="/" class="logo logo-dark">
        <span class="logo-sm">
          <img src="assets/images/logo.svg" alt="" height="30">
        </span>
      </a>

      <a routerLink="/" class="logo logo-light">
        <span class="logo-sm">
          <img src="assets/images/logo.svg" alt="" height="30">
        </span>
      </a>
    </div>
    <!-- end navbar-brand-box -->

    <!-- Start side-menu nav -->
    <div class="flex-lg-column" [ngClass]="{'my-auto': !hideSideMenuForNow}">
      <ul class="nav nav-pills side-menu-nav justify-content-center" role="tablist"
      (click)="getCurrentLoggedInUID()">
        <li class="nav-item" [customTooltip]="'Profile'" *ngIf="!hideSideMenuForNow">
          <a class="nav-link" id="pills-user-tab" (click)="activetab=1" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 1}">
            <i class="ri-user-2-line"></i>
          </a>
        </li>
        <li class="nav-item" [customTooltip]="'Chats'">
          <a class="nav-link active" id="pills-chat-tab" (click)="activetab=2" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 2}">
            <i class="ri-message-3-line"></i>
          </a>
        </li>
        <li class="nav-item" [customTooltip]="'Groups'" *ngIf="!hideSideMenuForNow">
          <a class="nav-link" id="pills-groups-tab" (click)="activetab=3" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 3}">
            <i class="ri-group-line"></i>
          </a>
        </li>
        <li class="nav-item" [customTooltip]="'Contacts'" placement="top" *ngIf="!hideSideMenuForNow">
          <a class="nav-link" id="pills-contacts-tab" (click)="activetab=4" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 4}">
            <i class="ri-contacts-line"></i>
          </a>
        </li>
        <li class="nav-item" [customTooltip]="'Settings'" *ngIf="!hideSideMenuForNow">
          <a class="nav-link" id="pills-setting-tab" (click)="activetab=5" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 5}">
            <i class="ri-settings-2-line"></i>
          </a>
        </li>


        <li class="nav-item dropdown profile-user-dropdown d-inline-block d-lg-none" ngbDropdown placement="top">
          <a class="nav-link dropdown-toggle" href="javascript: void(0);" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
            <img src="assets/images/users/avatar-1.jpg" alt="" class="profile-user rounded-circle">
          </a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a class="dropdown-item" href="#">{{'chat.profiledropdown.profile' | translate}} <i
                class="ri-profile-line float-end text-muted"></i></a>
            <a class="dropdown-item" href="#">{{'chat.profiledropdown.setting' | translate}} <i
                class="ri-settings-3-line float-end text-muted"></i></a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript: void(0);"
              (click)="logout()">{{'chat.profiledropdown.logout' | translate}} <i
                class="ri-logout-circle-r-line float-end text-muted"></i></a>
          </div>
        </li>
      </ul>
    </div>
    <!-- end side-menu nav -->

    <!-- Translations & theme start-->
    <div class="flex-lg-column d-none d-lg-block" *ngIf="!hideSideMenuForNow">
      <ul class="nav side-menu-nav justify-content-center">

        <li class="nav-item btn-group dropup profile-user-dropdown" ngbDropdown placement="top-left">
          <a class="nav-link dropdown-toggle" href="#" role="button" ngbDropdownToggle data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="ri-global-line"></i>
          </a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a class="dropdown-item" *ngFor="let item of listLang" (click)="setLanguage(item.lang)"
              [ngClass]="{'active': lang === item.lang}" href="javascript: void(0);">
              <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
                class="align-middle">{{item.text}}</span>
            </a>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link" id="light-dark" target="_blank" href="//chatvia-light.angular.themesbrand.com" [customTooltip]="'Light Mode'">
            <i class="ri-sun-line theme-mode-icon"></i>
          </a>
        </li>

        <li class="nav-item btn-group dropup profile-user-dropdown" ngbDropdown placement="top-left">
          <a class="nav-link dropdown-toggle" href="#" role="button" ngbDropdownToggle data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <img src="assets/images/users/avatar-1.jpg" alt="" class="profile-user rounded-circle">
          </a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a class="dropdown-item" href="#">{{'chat.profiledropdown.profile' | translate}} <i
                class="ri-profile-line float-end text-muted"></i></a>
            <a class="dropdown-item" href="#">{{'chat.profiledropdown.setting' | translate}} <i
                class="ri-settings-3-line float-end text-muted"></i></a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript: void(0);"
              (click)="logout()">{{'chat.profiledropdown.logout' | translate}} <i
                class="ri-logout-circle-r-line float-end text-muted"></i></a>
          </div>
        </li>
      </ul>
    </div>
    <!-- Translations & theme end-->
    <!-- Side menu user -->
  </div>
  <!-- start chat-leftsidebar -->
  <div class="chat-leftsidebar me-lg-1 h-100">
    <div class="tab-content h-100">
      <!-- Start Profile tab-pane -->
      <div id="pills-user" class="h-100" role="tabpanel" aria-labelledby="pills-user-tab" *ngIf="activetab === 1">
        <app-profile></app-profile>
      </div>
      <!-- End Profile tab-pane-->

      <!-- Start chats tab-pane -->
      <div id="pills-chat" class="h-100" role="tabpanel" aria-labelledby="pills-chat-tab" *ngIf="activetab === 2">
        <app-chats></app-chats>
      </div>
      <!-- End chats tab-pane -->

      <!-- Start groups tab-pane -->
      <div id="pills-groups" class="h-100" role="tabpanel" aria-labelledby="pills-groups-tab" *ngIf="activetab === 3">
        <app-groups></app-groups>
      </div>
      <!-- End groups tab-pane -->

      <!-- Start contacts tab-pane -->
      <div id="pills-contacts" class="h-100" role="tabpanel" aria-labelledby="pills-contacts-tab" *ngIf="activetab === 4">
        <app-contacts></app-contacts>
      </div>
      <!-- End contacts tab-pane -->

      <!-- Start settings tab-pane -->
      <div id="pills-setting" role="tabpanel" aria-labelledby="pills-setting-tab" *ngIf="activetab === 5">
        <app-settings></app-settings>
      </div>
      <!-- End settings tab-pane -->
    </div>

  </div>

  <!-- Start User chat -->
  <div class="user-chat w-100" id="chat-room">
    <div class="d-lg-flex">

      <!-- start chat conversation section -->
      <div class="w-100 position-relative">
        <!-- Start chat user head -->
        <div class="p-3 p-lg-4 border-bottom chat-user-head" *ngIf="fromUIDChatHeadInfo">
          <div class="row align-items-center">
            <div class="col-sm-4 col-8">
              <div class="d-flex align-items-center">
                <div class="d-block d-lg-none me-2 ms-0">
                  <a href="javascript: void(0);" class="user-chat-remove text-muted font-size-16 p-2"
                    (click)="closeUserChat()"><i class="ri-arrow-left-s-line"></i></a>
                </div>
                <div class="me-3 ms-0">
                  <div class="chat-user-img align-self-center" [ngClass]="{'online': fromUIDChatHeadInfo.status === 'online',
                                  'away': fromUIDChatHeadInfo.status === 'away'}">
                    <!-- <img src="assets/images/users/avatar-4.jpg" class="rounded-circle avatar-xs" alt=""> -->
                
                    <img *ngIf="userType" src="assets/images/group/group.png" class="rounded-circle avatar-xs" alt="">
                    <div class="avatar-xs" *ngIf="!userType">
                      <span class="avatar-title rounded-circle custom-bg-blue">
                        {{fromUIDChatHeadInfo.name ? ((fromUIDChatHeadInfo.name | translate).charAt(0)):'Error'}}
                      </span>
                    </div>
                    <span *ngIf="fromUIDChatHeadInfo.status" class="user-status"></span>
                  </div>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h5 class="font-size-16 mb-0 text-truncate">
                    <a href="#" class="text-reset user-profile-show">{{fromUIDChatHeadInfo.name | translate}}</a> 
                      <!-- <i class="ri-record-circle-fill font-size-10 text-success d-inline-block ms-1"></i> -->
                      <ng-container *ngIf="fromUIDChatHeadInfo.isTyping">
                        <ng-container *ngIf="userType">
                          <div class="d-flex align-items-baseline">
                            <span class="text-truncate custom-max-width-50 user-typing">{{fromUIDChatHeadInfo.typerName}}</span>
                            <span class="isTypingText">is typing</span>
                            <span class="animate-typing">
                              <span class="dot ms-1"></span>
                              <span class="dot ms-1"></span>
                              <span class="dot ms-1"></span>
                            </span>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!userType">
                          <div>
                            <span class="isTypingText ms-0">is typing</span>
                            <span class="animate-typing">
                              <span class="dot ms-1"></span>
                              <span class="dot ms-1"></span>
                              <span class="dot ms-1"></span>
                            </span>
                          </div>
                        </ng-container>
                      </ng-container>
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-sm-8 col-4">
              <ul class="list-inline user-chat-nav text-end mb-0">
              
                <li class="list-inline-item">
                  <div class="dropdown" ngbDropdown>
                    <button class="btn nav-btn dropdown-toggle" (click)="openSrchOffCanvas(srchListContent)" ngbDropdownToggle type="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="ri-search-line"></i>
                    </button>
                    <div class="dropdown-menu p-0 dropdown-menu-right dropdown-menu-md" ngbDropdownMenu>
                      <!-- <div class="search-box p-2">
                        <input type="text" class="form-control bg-light border-0"
                          placeholder="{{'chat.chatpanelheader.search.placeholder' | translate}}">
                      </div> -->
                    </div>
                    <ng-template #srchListContent let-srchOffcanvas>
                      <div class="offcanvas-header">
                        <div class="search-box chat-search-box w-100">
                          <div class="input-group rounded-3">
                            <span class="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon1">
                              <i class="ri-search-line search-icon font-size-18"></i>
                            </span>
                            <input type="text" class="form-control bg-light"
                              placeholder="{{'chat.chatpanelheader.search.placeholder' | translate}}" aria-label="Search chat messages"
                              aria-describedby="basic-addon1" [(ngModel)]="usrChatSrchQuery" (ngModelChange)="onChatSrchQryChange($event)">
                            <span class="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon2">
                              <i *ngIf="usrChatSrchQuery && (usrChatSrchQuery.trim().length!==0)" class="ri-close-line close-icon font-size-18"
                                (click)="clearUserChatSearch()"></i>
                            </span>
                          </div>
                        </div>
                        <button type="button" class="btn-close custom-srch-offcanvas-close" aria-label="Close" (click)="srchOffcanvas.dismiss('Cross click')"></button>
                      </div>
                      <h4 class="offcanvas-title px-3">Search Results</h4>
                      <div class="offcanvas-body px-0 position-relative">
                        <ul class="list-group rounded-0" *ngIf="usrChatSrchList && (usrChatSrchList.length>0)">
                          <li class="list-group-item c-pointer" *ngFor="let srchItem of usrChatSrchList;let i=index" (click)="usrChatSrchResultJump(srchItem)">
                            <div><span class="srchListDate">{{srchItem.isToday?srchItem.onlyTime:srchItem.noTime}}</span></div>
                            <div *ngIf="srchItem.hasOwnProperty('fileSize') && srchItem.fileSize"><i class="ri-file-text-fill align-bottom"></i><span>{{srchItem.fileName}}</span></div>
                            <div *ngIf="(srchItem.hasOwnProperty('fileSize') && !srchItem.fileSize) || !srchItem.hasOwnProperty('fileSize')">{{srchItem.message}}</div>
                          </li>
                        </ul>
                        <app-ui-loader [ngClass]="{'d-none':!isLoadingSrchData}" [transparentBackground]="true"></app-ui-loader>
                        <!-- <div class="loaderContainer"></div> -->
                      </div>
                    </ng-template>
                  </div>
                </li>
                <li class="list-inline-item d-none d-lg-inline-block me-2 ms-0" *ngIf="!hideFeaturesForNow">
                  <button type="button" class="btn nav-btn" data-bs-toggle="modal" data-bs-target="#audiocallModal"
                    (click)="openCallModal(callContent)">
                    <i class="ri-phone-line"></i>
                  </button>
                </li>
                <ng-template #callContent>
                  <div class="modal-body">
                    <div class="text-center p-4">
                      <div class="avatar-lg mx-auto mb-4">
                        <img src="assets/images/users/avatar-4.jpg" alt="" class="img-thumbnail rounded-circle">
                      </div>
              
                      <h5 class="text-truncate">Doris Brown</h5>
                      <p class="text-muted">Start Audio Call</p>
              
                      <div class="mt-5">
                        <ul class="list-inline mb-1">
                          <li class="list-inline-item px-2 me-2 ms-0">
                            <button type="button" class="btn btn-danger avatar-sm rounded-circle" data-bs-dismiss="modal">
                              <span class="avatar-title bg-transparent font-size-20">
                                <i class="ri-close-fill"></i>
                              </span>
                            </button>
                          </li>
                          <li class="list-inline-item px-2">
                            <button type="button" class="btn btn-success avatar-sm rounded-circle">
                              <span class="avatar-title bg-transparent font-size-20">
                                <i class="ri-phone-fill"></i>
                              </span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <li class="list-inline-item d-none d-lg-inline-block me-2 ms-0" *ngIf="!hideFeaturesForNow">
                  <button type="button" class="btn nav-btn" data-bs-toggle="modal" data-bs-target="#videocallModal"
                    (click)="openVideoModal(videoContent)">
                    <i class="ri-vidicon-line"></i>
                  </button>
                </li>
                <ng-template #videoContent>
                  <div class="modal-body">
                    <div class="text-center p-4">
                      <div class="avatar-lg mx-auto mb-4">
                        <img src="assets/images/users/avatar-4.jpg" alt="" class="img-thumbnail rounded-circle">
                      </div>
              
                      <h5 class="text-truncate">Doris Brown</h5>
                      <p class="text-muted mb-0">Start Video Call</p>
              
                      <div class="mt-5">
                        <ul class="list-inline mb-1">
                          <li class="list-inline-item px-2 me-2 ms-0">
                            <button type="button" class="btn btn-danger avatar-sm rounded-circle" data-bs-dismiss="modal">
                              <span class="avatar-title bg-transparent font-size-20">
                                <i class="ri-close-fill"></i>
                              </span>
                            </button>
                          </li>
                          <li class="list-inline-item px-2">
                            <button type="button" class="btn btn-success avatar-sm rounded-circle">
                              <span class="avatar-title bg-transparent font-size-20">
                                <i class="ri-vidicon-fill"></i>
                              </span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <li class="list-inline-item d-none d-lg-inline-block" *ngIf="!hideFeaturesForNow">
                  <button type="button" class="btn nav-btn user-profile-show" (click)="showUserProfile()">
                    <i class="ri-user-2-line"></i>
                  </button>
                </li>
              
                <li class="list-inline-item" *ngIf="userType==='group' && loggedInUserRole==='ADMIN'"><!-- Only shown in groups to admin -->
                  <div class="dropdown" ngbDropdown>
                    <button class="btn nav-btn dropdown-toggle" ngbDropdownToggle type="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="ri-more-fill"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                      <a *ngIf="!hideFeaturesForNow" class="dropdown-item d-block d-lg-none user-profile-show" (click)="showUserProfile()"
                        href="javascript: void(0);">{{'chat.chatpanelheader.dropdown.viewprofile' | translate}} <i
                          class="ri-user-2-line float-end text-muted"></i></a>
                      <a *ngIf="!hideFeaturesForNow" class="dropdown-item" href="#">{{'chat.chatpanelheader.dropdown.archive' | translate}} <i
                          class="ri-archive-line float-end text-muted"></i></a>
                      <a *ngIf="!hideFeaturesForNow" class="dropdown-item" href="#">{{'chat.chatpanelheader.dropdown.muted' | translate}} <i
                          class="ri-volume-mute-line float-end text-muted"></i></a>
                      <a (click)="deleteAllMsg(true)" class="dropdown-item" href="#">{{'chat.chatpanelheader.dropdown.deleteAll' | translate}} <i
                          class="ri-delete-bin-line float-end text-muted"></i></a>
                    </div>
                  </div>
                </li>
              
              </ul>
            </div>
          </div>
        </div>
        <!-- end chat user head -->

        <!-- start chat conversation  (psScrollY)="onListScroll($event)"-->
        <!-- <perfect-scrollbar class="chat-conversation p-3 p-lg-4" [ngClass]="{'d-none':showStartChatBanner}"
        #perfectScroll (psYReachStart)="onScrollEvent($event)"> -->
        <div class="chat-conversation p-3 p-lg-4" [ngClass]="{'d-none':showStartChatBanner}"
          infiniteScroll [infiniteScrollDisabled]="isLoadingData" [infiniteScrollUpDistance]="scrollUpApiHitThreshold"
          [infiniteScrollDistance]="scrollDownApiHitThreshold" [infiniteScrollThrottle]="500" [scrollWindow]="false"
          (scrolledUp)="onListUpScroll($event)" (scrolled)="onListDownScroll($event)" [alwaysCallback]="true" style="overflow-y: auto;">
          <div *ngIf="isLoadingData && !showLoader" class="msgDivLoaderContainer d-flex align-items-center justify-content-center">
            <div class="msgDivLoader">
              <div class="loader-wheel"></div>
              <!-- <div class="loader-text"></div> -->
            </div>
          </div>
          <ul class="list-unstyled mb-0" #scrollframe>
            <li class="msgListItem mtem_{{i}}" [id]="data.msgId"
            *ngFor="let data of messageList;let i=index"
              [ngClass]="{'right': data.align === 'right'}" #itemForScroll>
        
              <div class="chat-day-title" *ngIf="data.isToday && !data.hideTime">
                <span class="title">Today</span>
              </div>
              <div class="chat-day-title" *ngIf="!data.isToday && !data.hideTime">
                <span class="title">{{data.noTime}}</span>
              </div>
        
              <div class="conversation-list" [ngClass]="{'mb-0': data.hideName,'pe-3': (data.align === 'right') && ( (!userType) || (userType!=='group')), 'ps-3': (data.align !== 'right') && ( (!userType) || (userType!=='group'))}"
              intersectionObserver [intersectionDebounce]="400" [intersectionDisable]="!data.hasOwnProperty('isRead') || (data.hasOwnProperty('isRead') && data.isRead)
              || (data.hasOwnProperty('isRead') && !data.isRead && (currentFromUID===data.id))" (visibilityChange)="onIntersectionVisibilityChanged(data, $event)">
                <div class="chat-avatar" [ngClass]="{'d-none': (!userType) || (userType!=='group')}">
                  <img *ngIf="data.profile" src="{{data.profile}}" alt="" [ngClass]="{'invisible': data.hideName}">
                  <div class="avatar-xs" *ngIf="!data.profile" [ngClass]="{'invisible': data.hideName}">
                    <span class="avatar-title rounded-circle custom-bg-blue">
                      {{data.name?((data.name | translate).charAt(0)):'Error'}}
                    </span>
                  </div>
                </div>
        
                <div class="user-chat-content">
                  <div class="ctext-wrap" [ngClass]="{'showAsRead_1':data.hasOwnProperty('isRead') && data.isRead && (currentFromUID===data.id),
                  'justify-content-end': data.align === 'right','justify-content-start': data.align === 'left'}">
                    <div class="ctext-wrap-content showBubbleTailFin" [ngClass]="{'showBubbleTailFinTemp': data.hasOwnProperty('addChatBubbleFin') && data.addChatBubbleFin}">
                      <p class="mb-0" *ngIf="!data.isFile">
                        {{data.message | translate}}
                      </p>
        
                      <ul class="list-inline message-img mb-0" *ngIf="data.isimage">
                        <li class="list-inline-item message-img-list" *ngFor="let item of data.imageContent">
                          <div>
                            <a class="popup-img d-inline-block m-1" href="#" title="Project 1">
                              <img :src="{{item}}" alt="" class="rounded border">
                            </a>
                          </div>
                          <div class="message-img-link">
                            <ul class="list-inline mb-0">
                              <li class="list-inline-item">
                                <a href="#">
                                  <i class="ri-download-2-line"></i>
                                </a>
                              </li>
                              <li class="list-inline-item dropdown" ngbDropdown>
                                <a class="dropdown-toggle" href="#" role="button" ngbDropdownToggle data-toggle="dropdown"
                                  aria-haspopup="true" aria-expanded="false">
                                  <i class="ri-more-fill"></i>
                                </a>
                                <div class="dropdown-menu" ngbDropdownMenu>
                                  <a class="dropdown-item" href="#">{{'chat.messages.dropdown.copy' | translate}} <i
                                      class="ri-file-copy-line float-end text-muted"></i></a>
                                  <a class="dropdown-item" href="#">{{'chat.messages.dropdown.save' | translate}} <i
                                      class="ri-save-line float-end text-muted"></i></a>
                                  <a class="dropdown-item" href="#">{{'chat.messages.dropdown.forward' | translate}} <i
                                      class="ri-chat-forward-line float-end text-muted"></i></a>
                                  <a class="dropdown-item" href="#">{{'chat.messages.dropdown.delete' | translate}} <i
                                      class="ri-delete-bin-line float-end text-muted"></i></a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
        
                      <div class="card p-2 mb-2" *ngIf="data.isFile">
                        <ngb-progressbar class="mb-1" *ngIf="data.uploadPercent" type="info" [value]="data.uploadPercent"
                        [striped]="true" [animated]="true" height=".5rem"></ngb-progressbar>
                        <div class="d-flex align-items-center">
                          <div class="avatar-sm me-3 ms-0">
                            <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                              <i class="ri-file-text-fill"></i>
                            </div>
                          </div>
                          <div class="d-flex-body">
                            <div class="text-start">
                              <h5 class="font-size-14 mb-1">{{data.fileName}}</h5>
                              <p class="text-muted font-size-13 mb-0">{{data.fileSize}}</p>
                            </div>
                          </div>
        
                          <div class="ms-4">
                            <ul class="list-inline mb-0 font-size-20" *ngIf="data.fileContent">
                              <li class="list-inline-item">
                                <a href="#" class="text-muted" (click)="downloadFile(data.fileContent, data.fileName)">
                                  <i class="ri-download-2-line"></i>
                                </a>
                              </li>
                              <li class="list-inline-item dropdown d-none" ngbDropdown>
                                <a class="dropdown-toggle text-muted" href="#" ngbDropdownToggle role="button"
                                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i class="ri-more-fill"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                  <a class="dropdown-item" href="#">{{'chat.messages.filedropdown.share' | translate}}
                                    <i class="ri-share-line float-end text-muted"></i></a>
                                  <a class="dropdown-item" href="#">{{'chat.messages.filedropdown.delete' | translate}}
                                    <i class="ri-delete-bin-line float-end text-muted"></i></a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
        
                      <p class="chat-time mb-0 justify-content-end" *ngIf="!data.istyping">
                        <!-- <i class="ri-time-line align-middle" placement="top" [ngbTooltip]="data.dateTime"></i>  -->
                        <span class="align-middle ms-1">{{data.onlyTime | translate}}</span>
                        <i class="ri-check-double-line ms-1" *ngIf="((currentFromUID===data.id) && data['msgId'])"
                        [ngClass]="{'readIcon':data.isRead, 'unreadIcon':!data.isRead}"></i>
                      </p>
                    </div>
        
                    <div class="dropdown align-self-start" ngbDropdown
                    *ngIf="data.id===currentFromUID||(userType && (userType==='group') && (loggedInUserRole==='ADMIN'))">
                      <a class="dropdown-toggle" href="#" role="button" ngbDropdownToggle data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-2-fill"></i>
                      </a>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a *ngIf="!hideFeaturesForNow" class="dropdown-item" href="#">{{'chat.messages.dropdown.copy' | translate}} <i
                            class="ri-file-copy-line float-end text-muted"></i></a>
                        <a *ngIf="!hideFeaturesForNow" class="dropdown-item" href="#">{{'chat.messages.dropdown.save' | translate}} <i
                            class="ri-save-line float-end text-muted"></i></a>
                        <a *ngIf="!hideFeaturesForNow" class="dropdown-item" href="#">{{'chat.messages.dropdown.forward' | translate}} <i
                            class="ri-chat-forward-line float-end text-muted"></i></a>
                        <a (click)="deleteAllMsg(false, data, i)" class="dropdown-item" href="#">{{'chat.messages.dropdown.delete' |
                          translate}} <i class="ri-delete-bin-line float-end text-muted"></i></a>
                        <a (click)="banUser(banUserConfirmContent, data, i)" *ngIf="(userType && (userType==='group') && (loggedInUserRole==='ADMIN'))" class="dropdown-item" href="#">Ban User <i class="ri-delete-bin-line float-end text-muted"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="ctext-wrap" *ngIf="data.message2">
                    <div class="ctext-wrap-content">
                      <p class="mb-0">
                        {{data.message2 | translate}}
                      </p>
                      <p class="chat-time mb-0"><i class="ri-time-line align-middle"></i> <span class="align-middle">{{data.onlyTime
                          |
                          translate}}</span></p>
                    </div>
                    <div class="dropdown align-self-start" ngbDropdown>
                      <a class="dropdown-toggle" href="#" role="button" ngbDropdownToggle data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-2-fill"></i>
                      </a>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" href="#">{{'chat.messages.dropdown.copy' | translate}} <i
                            class="ri-file-copy-line float-end text-muted"></i></a>
                        <a class="dropdown-item" href="#">{{'chat.messages.dropdown.save' | translate}} <i
                            class="ri-save-line float-end text-muted"></i></a>
                        <a class="dropdown-item" href="#">{{'chat.messages.dropdown.forward' | translate}} <i
                            class="ri-chat-forward-line float-end text-muted"></i></a>
                        <a class="dropdown-item" href="#">{{'chat.messages.dropdown.delete' | translate}} <i
                            class="ri-delete-bin-line float-end text-muted"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="conversation-name pt-1" *ngIf="!data.hideName &&(userType && (userType==='group'))">{{data.name | translate}}
                  </div>
                </div>
              </div>
            </li>
            <li *ngIf="fromUIDChatHeadInfo && fromUIDChatHeadInfo.isTyping">
              <div class="conversation-list">
                <div class="chat-avatar me-1">
                  <div class="avatar-xs" *ngIf="fromUIDChatHeadInfo.isTyping" [ngClass]="{'invisible': !fromUIDChatHeadInfo.isTyping}">
                    <span class="avatar-title rounded-circle custom-bg-blue">
                      {{fromUIDChatHeadInfo.typerName?((fromUIDChatHeadInfo.typerName | translate).charAt(0)):'Error'}}
                    </span>
                  </div>
                </div>
                <div class="conversation-name">{{fromUIDChatHeadInfo.typerName | translate}}</div>
                <div>
                  <span class="animate-typing" *ngIf="fromUIDChatHeadInfo.isTyping">
                    <span class="dot ms-1"></span>
                    <span class="dot ms-1"></span>
                    <span class="dot ms-1"></span>
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- </perfect-scrollbar> -->
        <!-- end chat conversation end -->
        <ng-template #banUserInformContent let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Ban Info</h4>
          </div>
          <div class="modal-body">
            <div>You have been banned from this group for breaking group moderation policy.</div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
          </div>
        </ng-template>
        <ng-template #banUserConfirmContent let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Ban Confirmation</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
          </div>
          <div class="modal-body">
            <div>Are you sure, you want to ban the user from this group for breaking group moderation policy.</div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('cancel click')">Cancel</button>
            <button type="button" class="btn btn-danger" (click)="modal.close('Ok click')">Ok</button>
          </div>
        </ng-template>
        
        <div class="chat-welcome-section" [ngClass]="{'d-none':!showStartChatBanner}">
          <div class="row w-100 justify-content-center">
            <div class="col-xxl-5 col-md-7">
              <div class="p-4 text-center">
                <div class="avatar-xl mx-auto mb-4">
                  <div class="avatar-title custom-bg-blue rounded-circle"> <svg data-v-5e8ea5c2="" xmlns="http://www.w3.org/2000/svg" width="70px" height="65px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square">
                      <path data-v-5e8ea5c2="" d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z">
                      </path>
                    </svg></div>
                </div>
                <h4 class="d-inline px-3 py-2 rounded-pill custom-bg-blue text-white fs-4"> Start Conversation </h4>
              </div>
            </div>
          </div>
        </div>
        <app-ui-loader [ngClass]="{'d-none':!showLoader}" [transparentBackground]="false"></app-ui-loader>

        <!-- start chat input section -->
        <div class="p-3 pb-0 p-sm-4 border-top mb-0 chat-input-section" [ngClass]="{'d-none':showStartChatBanner}">
          <div class="row no-gutters">
            <div class="col">
              <div>
                <input type="text" class="form-control form-control-lg bg-light border-light" [disabled]="stopScrollCheck" [(ngModel)]="newMessage"
                  [placeholder]="!stopScrollCheck?'Enter Message...':'Not Allowed'" (input)="sendTyping()" (keyup)="keyUpMainFunction($event)">
              </div>
            </div>
            <div class="col-auto">
              <div class="chat-input-links ms-md-2">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item" *ngIf="!hideFeaturesForNow">
                    <button type="button" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                      data-toggle="tooltip" data-placement="top" [customTooltip]="'Emoji'">
                      <i class="ri-emotion-happy-line"></i>
                    </button>
                  </li>
                  <li class="list-inline-item">
                    <button type="button" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                      data-toggle="tooltip" data-placement="top" [customTooltip]="'Attached File'"
                      (click)="uploadFile(fileInput, 'i')">
                      <i class="ri-attachment-line"></i>
                    </button>
                    <input style="display: none" type="file" (change)="onFileChanged($event, 'any', 'i')"
                    #fileInput>
                  </li>
                  <li class="list-inline-item">
                    <button type="submit"
                      class="btn btn-primary custom-button-blue font-size-16 btn-lg chat-send waves-effect waves-light"
                      (click)="sendMessage()" [disabled]="stopScrollCheck">
                      <i class="ri-send-plane-2-fill"></i>
                    </button>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
        <!-- end chat input section -->
      </div>
      <!-- end chat conversation section -->

      <app-profile-detail></app-profile-detail>
    </div>
  </div>
</div>
