import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { chat } from './data';
import { Chats } from './chats.model';
import { TranslateService } from '@ngx-translate/core';
import { InterComponentDataService } from '../../services/inter-component-data.service';
import { EMPTY, of, Subject, Subscription } from 'rxjs';
import { catchError, concatMap, debounceTime } from 'rxjs/operators';
import { UserVerifyService } from 'src/app/services/user-verify.service';
import { ChatService } from '../../services/chat.service';
import { ToastAlertsService } from 'src/app/reusables/toast-alerts/toast-alerts.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
/**
 * Tab-chat component
 */
export class ChatsComponent implements OnInit, OnDestroy {
  private subscriptionArr: Subscription = new Subscription(); // for tracking all subscription on page

  hideFeaturesForNow = true;// to hide feature not implemented for now

  chatArr: Chats[];
  usrSrchQuery: any; // bind this to input with ngModel
  srchQueryChanged: Subject<string> = new Subject<string>();
  currentFromUID: string;
  currentFromName: string;
  showLoader1 = false;

  constructor(public translate: TranslateService, private dataService: InterComponentDataService
    , private userService: UserVerifyService, private chatService: ChatService,
    private cdref: ChangeDetectorRef, private toastService: ToastAlertsService, private datePipe: DatePipe) {
      const sub1 = this.srchQueryChanged
      .pipe(debounceTime(500))
      .subscribe(qry => {
        console.log('query inside srchQueryChanged subscription: ', qry);
          this.usrSrchQuery = qry.trim();
          // api call
          // if(this.usrSrchQuery) {
            this.getDataFromSearchAPI(this.usrSrchQuery);
          // }
      });
      this.subscriptionArr.add(sub1);
    }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    dots: false,
    margin: 16,
    navSpeed: 700,
    items: 4,
    nav: false
  };

  ngOnInit(): void {
    let sub1 = this.dataService.dataReceivedEvent.pipe(concatMap((result: any) => {
      if (result.eventName === 'YourUserId') {
        this.currentFromUID = result.data;
        this.currentFromName = this.dataService.currentUserObj.uname;
        if(!result.suppressLoader){
          this.showLoader1 = true;
        }
        this.cdref.detectChanges();
        return of(result.data);
      } else {
        if (result.eventName === 'onlineStatusRefreshed') {
          if(this.chatArr && (this.chatArr.length>0)) {
            let tElem = [];
            const foundIndex = this.chatArr.findIndex(x => {
              if(x.id == result.data.userId) {
                x.status = result.data.status;
                this.cdref.detectChanges();
                // tElem.push(x);
                return true;
              }
            });
          }
        }
        if (result.eventName === 'typingStatusRecieved') {
          if(this.chatArr && (this.chatArr.length>0)) {
            const foundIndex = this.chatArr.findIndex(x => {
              if(result.data.type && result.data.type==='group'){/* Only for group */
                if (x.id == result.data.toUID) {
                  if (x.isTyping) {// do nothing and ignore subsequent 'typing' event until "x.isTyping === false"

                  } else {
                    x.isTyping = true;
                    x.typerName = this.capitalizeFirstLetter(result.data.fromName);/* Only for group */
                    setTimeout(() => {
                      x.isTyping = false;
                    }, 1000);
                  }
                  return true;
                }
              } else { 
                if (x.id == result.data.fromUID) {
                  if (x.isTyping) {// do nothing and ignore subsequent 'typing' event until "x.isTyping === false"

                  } else {
                    x.isTyping = true;
                    setTimeout(() => {
                      x.isTyping = false;
                    }, 1000);
                  }
                  return true;
                }
              }
              return false;
            });
          }
        }
        if (result.eventName === 'refreshOnMsgSent') {
          if(this.chatArr && (this.chatArr.length>0)) {
            const tday = new Date();
            const todayIsoString = tday.toISOString();
            const foundIndex = this.chatArr.findIndex(x => {
              if(x.id == result.data.to) {
                if(result.data.isFile){/* only when any file is uploaded */
                  x.lastMessage = result.data.fileName;
                } else {
                  x.lastMessage = result.data.msg;
                }
                x.time = this.getLastMsgDisplayTime(tday, todayIsoString, result.data.time);
                this.cdref.detectChanges();
                // tElem.push(x);
                return true;
              }
            });
          }
        }
        if (result.eventName === 'refreshOnMsgRecieved') {
          if(this.chatArr && (this.chatArr.length>0)) {
            const tday = new Date();
            const todayIsoString = tday.toISOString();
            const foundIndex = this.chatArr.findIndex(x => {
              if(x.id == result.data.from) {
                x.lastMessage = result.data.msg;
                x.time = this.getLastMsgDisplayTime(tday, todayIsoString, result.data.time);
                this.cdref.detectChanges();
                // tElem.push(x);
                return true;
              }
            });
          }
        }
        /* Stopping pipe stream chain in case non-required events fire as it 
        creates error in next concatMap request in pipeline*/
        return EMPTY;
      }
    }),
      concatMap((resData: any) => {
        console.log('resData :: ', resData);
        return this.userService.getRecentUserList({ userId: resData }).pipe(
          /* Very important to keep outer observable subscription alive if
          inner observable errors out in concatMap*/
          catchError((error) => {
            console.log('!! error in catchError !!', error);
            this.showDanger('Something went wrong :: '+ error);
            return EMPTY; // as catchError requires an observable to be emitted 
          }));
      })).subscribe((resp: any) => {
        this.showLoader1 = false;
        console.log('resp in getRecentUserList:: ', resp);
        this.chatArr = resp.data.list;
        // let tempArr = resp.map((elem: any)=> {
        const tday = new Date();
        const todayIsoString = tday.toISOString();
        console.log('tday in getRecentUserList:: ', todayIsoString, ', ', tday.getDate(), ', ', tday.getDay());
        const tempJumpToChatData = this.dataService.getCurrentJumpToChatData();
        if(tempJumpToChatData && (tempJumpToChatData.hasOwnProperty('jumpedDirectlyToChat'))){
          console.log('tempJumpToChatData in getRecentUserList:: ', tempJumpToChatData);
          if(resp.data.hasOwnProperty('bannedGroups') && (resp.data.bannedGroups.length>0)) {
            if(!resp.data.bannedGroups.includes(tempJumpToChatData.id)){
                    this.dataService.setDataToSend({
                      eventName: 'GetMessages', //eventname can be anything
                      data: {fromUID: this.dataService.currentFromUID, toUID: tempJumpToChatData['id'], ...(tempJumpToChatData['groupType'] && {type:'group'})},
                      resetScrollbar: true,
                      jumpedDirectlyToChat:true // only when we jump directly to specific chat
                    });
                    this.cdref.detectChanges();
                    document.getElementById('chat-room').classList.add('user-chat-show');
            }
          }

        }
        this.chatArr.forEach(elem => {
          if (elem.hasOwnProperty('msgUpdatedAt') && elem.msgUpdatedAt) {
            elem.time = this.getLastMsgDisplayTime(tday, todayIsoString, elem.msgUpdatedAt);
            elem.name = this.capitalizeFirstLetter(elem.name);
          }
        });
        //   return elem;
        // })
        // this.ctrlImagesArr = tempArr;
        // console.log('ctrlImagesArr :: ', this.ctrlImagesArr);
      },
      (err: any) => {
        this.showLoader1 = false;
      //   this.loading1 = false;
      //   localStorage.clear();
        console.log('error Status', err.status);
        this.showDanger('Something went wrong :: '+ err);
        // if (err.status === 401) { // When unauthenticated access due to wrong token or session expire
        //   this.saveRedirectUrlAndLogoutToMainPage();
        // } else { // When unauthenticated access due to reasons like partner info not available, server down
        //   this.userService.logout();
        // }

        // no user login so redirect to login page
        // this.router.navigate(['']);
        // observer.next(false);
        // observer.complete();
      });
    this.subscriptionArr.add(sub1);
    // this.chatArr = chat;
  }

	showDanger(msg) {
		this.toastService.show(msg, { classname: 'bg-danger text-light', delay: 3000 });
	}
  
  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  isoTimeTransform(isoString: any, noTimeStatus?:boolean, dateTime?:boolean) {
    if(dateTime){
      return this.datePipe.transform(isoString, "dd/MMM/yyyy H:mm");
    }
    if(noTimeStatus) {
      return this.datePipe.transform(isoString, "dd MMM yyyy");
    } else {
      return this.datePipe.transform(isoString, "H:mm");
    }
  }

  getLastMsgDisplayTime(todayDateObject, todayIsoString, lastMsgUpdatedIsoString) {
    const fulldays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const lastMsgDt = new Date(lastMsgUpdatedIsoString);
    const isToday = (this.isoTimeTransform(lastMsgUpdatedIsoString, true)===this.isoTimeTransform(todayIsoString, true));
    const diffDays: number = Math.abs(this.dateDiffInDays(new Date(todayDateObject),new Date(lastMsgUpdatedIsoString)));
    console.log('diffDays in getRecentUserList:: ', diffDays, this.isoTimeTransform(lastMsgUpdatedIsoString, true));
    if(isToday||(diffDays===0)) {
      return this.isoTimeTransform(lastMsgUpdatedIsoString); // only time
    } else {
      if(diffDays===1){
        return 'Yesterday';       
      } else if((diffDays>1) && (diffDays<7)){
        return fulldays[lastMsgDt.getDay()];
      } else if((diffDays===7) || (diffDays>7)){
        return this.isoTimeTransform(lastMsgUpdatedIsoString, true);
      }
    }
  }
  
  dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
  
  getDataFromSearchAPI(qryStr: string) {
    const postData = {
      userId: this.currentFromUID,
      txt: qryStr,
      eventId: this.chatService.getCurrentFromUserEventId()
    }

    
    this.showLoader1 = true;
    const sub  = this.userService.getSearchInRecentChatsList(postData).subscribe((resp: any) => {
      this.showLoader1 = false;
      console.log('resp in getSearchInRecentChatsList:: ', resp);
      this.chatArr = resp.data.filter((elem) => elem.id !== this.currentFromUID);
      // let tempArr = resp.map((elem: any)=> {
      const tday = new Date();
      const todayIsoString = tday.toISOString();
      console.log('tday in getSearchInRecentChatsList:: ', todayIsoString, ', ', tday.getDate(), ', ', tday.getDay());
      this.chatArr.forEach(elem => {
        if (elem.hasOwnProperty('msgUpdatedAt') && elem.msgUpdatedAt) {
          elem.time = this.getLastMsgDisplayTime(tday, todayIsoString, elem.msgUpdatedAt);
        }
      });
      //   return elem;
      // })
      // this.ctrlImagesArr = tempArr;
      // console.log('ctrlImagesArr :: ', this.ctrlImagesArr);
    },
    (err: any) => {
      this.showLoader1 = false;
    //   localStorage.clear();
      console.log('error Status', err.status);
      this.showDanger('Something went wrong :: '+ err);
      // if (err.status === 401) { // When unauthenticated access due to wrong token or session expire
      //   this.saveRedirectUrlAndLogoutToMainPage();
      // } else { // When unauthenticated access due to reasons like partner info not available, server down
      //   this.userService.logout();
      // }
    });

    this.subscriptionArr.add(sub);

  }
  
  onQryChange(query:any) {
    this.srchQueryChanged.next(query);
  }

  /**
   * Show user chat
   */
  // tslint:disable-next-line: typedef
  showChat(userId, toUserName, lastMsgId=null, type?: string) {
    // this.showDanger();
    this.chatService.setCurrentToUserDetails(userId, toUserName);
    this.dataService.setDataToSend({
      eventName: 'GetMessages', //eventname can be anything
      data: {fromUID: this.currentFromUID, toUID: userId, ...(type && {type:type})},
      lastMsgId:lastMsgId,
      resetScrollbar: true
    });

    if(this.usrSrchQuery && this.usrSrchQuery.trim()) {
      this.clearUserSearch();
    }

    document.getElementById('chat-room').classList.add('user-chat-show');
  }

  clearUserSearch() {
    this.usrSrchQuery = '';
    this.chatService.refreshUserList(false);
  }

  ngOnDestroy(): void {
    console.log('list of subscriptions before unsubscribing inside ngOnDestroy: ', this.subscriptionArr);
    this.subscriptionArr.unsubscribe();
  }
}
