<app-toasts></app-toasts>
<router-outlet></router-outlet>
<!-- Example iframe used on chatvia node demo site-->
<!-- <div id="drift-frame-chat" class="drift-conductor-item drift-frame-chat drift-frame-chat-align-right drift-chat-open"
    style="right: 12px; width: 400px !important; max-width: none; position: fixed; z-index: 2147483647; max-height: 220px !important; bottom: 88px; visibility: visible; min-width: 320px; height: calc(100% - 75px) !important; opacity: 1;">
    <iframe class="drift-frame-chat" scrolling="no" title="Drift Widget Chat Window"
        allow="autoplay; encrypted-media; fullscreen;" frameborder="0"
        src="https://js.driftt.com/core/chat?region=US&amp;driftEnableLog=false&amp;pageLoadStartTime=1665991485147"
        style="background: transparent; width: 400px !important; max-width: 100%; height: 100%; border: none;">
    </iframe>
</div> -->