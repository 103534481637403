import { NgModule} from '@angular/core';
import { CommonModule} from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiLoaderComponent } from './ui-loader/ui-loader.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ToastsContainer } from './toast-alerts/toast-alerts-container.component';
import { IntersectionObserverDirective } from './intersection-observer/intersection-observer.directive';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomTooltipDirective } from './custom-tooltip/custom-tooltip.directive';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        // NgbModule,
        NgbToastModule,
        InfiniteScrollModule,
        
        /* Material Modules */
    ],
    declarations: [
        UiLoaderComponent,
        ToastsContainer,
        IntersectionObserverDirective,
        CustomTooltipDirective
  ],
    providers: [], // providers can not be exported here and can only be exported with forRoot(). See below inside "export Class"
    exports: [
        CommonModule,
        FormsModule,
        // NgbModule,
        NgbToastModule,
        InfiniteScrollModule,

        /* Material Modules */

        UiLoaderComponent,
        ToastsContainer,
        IntersectionObserverDirective,
        CustomTooltipDirective
    ]
})
export class SharedModule {
    static forRoot() {
        return [{
            ngModule: SharedModule,
            providers: [
                
            ], // for exporting providers use this
        }, 
        // NgxLoadingModule.forRoot({})
    ];
    }
}
