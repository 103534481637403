
<div class="chat-loader-section">
    <div class="custom_loader_bg" style="display: block;"
    [ngClass]="{'white-bg':!transparentBackground}">
        <div class="text-center">
            <div class="custom_loader">
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</div>