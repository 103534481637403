import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/auth.models';
import { ChatService } from 'src/app/chat/services/chat.service';

@Injectable({ providedIn: 'root' })
export class AuthfakeauthenticationService {

    constructor(private http: HttpClient, private chatService: ChatService) {
    }

    login(email: string, password: string) {
        /* Sending hardcoded value for now until we integrate chat into main project*/
        console.log('AuthfakeauthenticationService: login: email: ', email, ' password: ', password);
        const tempHardCodedVal = { role:'ADMIN', userName: email, userId: password };
        this.chatService.checkUserDetailsForVerification(tempHardCodedVal, true);
        //   return of(tempHardCodedVal);

        // return this.http.post<any>(`/users/authenticate`, { email, password })
        //     .pipe(map(user => {
        //         // login successful if there's a jwt token in the response
        //         if (user && user.token) {
        //             // store user details and jwt token in local storage to keep user logged in between page refreshes
        //             // localStorage.setItem('currentUser', JSON.stringify(user));
        //             // this.currentUserSubject.next(user);
        //         }
        //         return user;
        //     }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        // this.currentUserSubject.next(null);
    }
}
