const Messages = [
    {
        id: 1,
        message: 'chat.messages.1.message',
        name: 'chat.messages.1.name',
        profile: 'assets/images/users/avatar-4.jpg',
        time: 'chat.messages.1.time'
    },
    {
        id: 2,
        message: 'chat.messages.2.message',
        name: 'chat.messages.2.name',
        profile: 'assets/images/users/avatar-1.jpg',
        time: 'chat.messages.2.time',
        align: 'right'
    },
    {
        isToday: true
    },
    {
        id: 3,
        message: 'chat.messages.3.message',
        message2: 'chat.messages.3.message2',
        name: 'chat.messages.3.name',
        profile: 'assets/images/users/avatar-4.jpg',
        time: 'chat.messages.3.time',
    },
    {
        id: 4,
        message: 'chat.messages.4.message',
        name: 'chat.messages.4.name',
        profile: 'assets/images/users/avatar-1.jpg',
        time: 'chat.messages.4.time',
        align: 'right'
    },
    {
        id: 5,
        name: 'chat.messages.5.name',
        profile: 'assets/images/users/avatar-1.jpg',
        time: 'chat.messages.5.time',
        isimage: true,
        imageContent: [
            'assets/images/small/img-1.jpg',
            'assets/images/small/img-2.jpg',
        ]
    },
    {
        id: 6,
        name: 'chat.messages.6.name',
        profile: 'assets/images/users/avatar-1.jpg',
        time: 'chat.messages.6.time',
        align: 'right',
        isfile: true,
        fileContent: 'admin_v1.0.zip',
        fileSize: '12.5 MB'
    },
    {
        id: 7,
        name: 'chat.messages.7.name',
        message: 'chat.messages.7.message',
        profile: 'assets/images/users/avatar-1.jpg',
        istyping: true
    }
];

const fileTypesAllowedCollection = ["text/csv",
    "text/xml",
    "image/gif",
    "image/jpeg",
    "text/plain",
    "image/avif",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon",
    "application/json",
    "application/msword",
    "application/pdf",
    "application/rtf",
    "application/vnd.apple.mpegurl",
    "application/vnd.ms-excel",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/zip",
    "application/x-zip-compressed",
    "audio/midi",
    "audio/mpeg",
    "audio/ogg",
    "audio/x-m4a",
    "audio/x-realaudio",
    "video/3gpp",
    "video/mp2t",
    "video/mp4",
    "video/mpeg",
    "video/quicktime",
    "video/webm",
    "video/x-flv",
    "video/x-m4v",
    "video/x-ms-wmv",
    "video/x-msvideo"
]

export { Messages, fileTypesAllowedCollection };
