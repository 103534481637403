import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { InterComponentDataService } from './chat/services/inter-component-data.service';
import { ChatService } from './chat/services/chat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  subscriptionArr: Subscription = new Subscription();
  pageReloading: boolean = false;

  @HostListener('window:message',['$event'])
  onMessage(e)
  {
    console.log('[[recieving postMessage]]', e);
  if (e.origin!=window.location.origin)/* Required for security purpose or else xss attacks */
    {
    return false;
    }
  if (e.data.hasOwnProperty('role') && Object.keys(e.data).length !== 0)
    {
      const postData = { 
        role:e.data['role'],
        userName: e.data['userName'],
        userId: e.data['userId'],
        // token:"onlyFake"
      }
      this.chatService.checkUserDetailsForVerification(postData, true);
    }
    if (e.data.hasOwnProperty('groupType') && Object.keys(e.data).length !== 0)
      {
        this.dataService.setDataToSend({
          eventName: 'GetMessages', //eventname can be anything
          data: {fromUID: this.dataService.currentFromUID, toUID: e.data['id'], ...(e.data['groupType'] && {type:'group'})},
          resetScrollbar: true,
          jumpedDirectlyToChat:true // only when we jump directly to specific chat
        });

        document.getElementById('chat-room').classList.add('user-chat-show');
      }
  }

  constructor(public translate: TranslateService, private router: Router
    , private route: ActivatedRoute, private dataService: InterComponentDataService
    , private chatService: ChatService) {
    translate.addLangs(['en', 'es', 'it', 'ru', 'de']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|it|es|ru|de/) ? browserLang : 'en');
    let sub1 = router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.pageReloading = !router.navigated;
          console.log('pageReloading :: ', this.pageReloading);
          console.log('[[sending pageReloading]]');
          const tempParsedUrl = router.parseUrl(event.url);
          console.log('AppComponent - Subscription NavigationStart: URL=', event.url,tempParsedUrl);
          if(tempParsedUrl.queryParamMap.keys.length !== 0 && !tempParsedUrl.queryParamMap.keys.includes('returnUrl')) {
            console.log('%c [[GOT Query PArams]] : ', "background: violet; color: black", tempParsedUrl);
            this.checkIfRequiredQryParamsExist(tempParsedUrl.queryParamMap);
          } else {/* If no query params exist */
            console.log('[[showing Loginpage]]');
            // setTimeout(() => {/* Required since not enough time to load login page before emitting false from here */
            //   this.chatService.setHideInitialLoginPageSubject(false);
            // }, 300);
          }
          this.dataService.setDataToSend({
            eventName: 'PageRefresh', //eventname can be anything
            data: this.pageReloading
          });
        }
        if (event instanceof NavigationEnd) {
          console.log('AppComponent - Subscription NavigationEnd: URL=', event.url);
        }
    });
    this.subscriptionArr.add(sub1);

    /* Test Url :: http://localhost:4300/account/login?userName=praddumn&userId=1234567&role=ADMIN */
    const sub2 = this.route.queryParamMap.subscribe((qRes:any)=>{
      console.log('Query PARAMS subscrition ::', qRes);
      // check whether our params object is not empty
      if (qRes.keys.length !== 0) {
        //`?role=${data.role}&userId=${data.pass}&userName=${data.userName}`
        this.checkIfRequiredQryParamsExist(qRes);
      }
    });

    this.subscriptionArr.add(sub2);

    console.log('SNAPSHOT PARAMS :', this.route.snapshot.queryParams);
    console.log('this.router.routerState.snapshot.url: ', this.router.routerState.snapshot.url);
    console.log('this.router.navigated: ', this.router.navigated);
    // check whether our params object is not empty
    if (Object.keys(this.route.snapshot.queryParams).length !== 0) {
        // router navigated gives whether page is navigated to or reloaded
        if (!this.router.navigated) {
            console.log('EMPTYING QUERYPARAMS ON RELOAD');

            // this location.replaceState replaces current url in history but doesn't navigates
            // this.location.replaceState(this.location.path().split('?')[0], '');

            // Navigates while replacing the current url in history.
            this.router.navigate([], { replaceUrl: true });
        } else {
            this.route.snapshot.queryParams;
        }
    }
  }

  ngOnInit() {
  }

  checkIfRequiredQryParamsExist(arr1) {
    const allReqKeysArr = ['role', 'userId', 'userName', 'eventId'];
    const allReqKeysPresent = allReqKeysArr.every((val) => arr1.keys.includes(val));
    if(allReqKeysPresent){
      const postData = { 
        role:arr1.params['role'],
        userName: arr1.params['userName'],
        userId: arr1.params['userId'],
        eventId: arr1.params['eventId'],
        // token:"onlyFake"
      }
      if(arr1.keys.includes('suppress_init_userchats_loader')) {// in case we want to suppress initial loader of recent chats like in unity/mobile
        this.chatService.setInitialRecentChatsWindowLoaderSuppressStatus(Boolean(arr1.params['suppress_init_userchats_loader']))
      }
      if(arr1.keys.includes('suppress_init_msgcontent_loader')) {// in case we want to suppress initial loader of msg content window like in unity/mobile
        this.chatService.setInitialMessagesContentWindowLoaderSuppressStatus(Boolean(arr1.params['suppress_init_msgcontent_loader']))
      }
      this.chatService.setCurrentFromUserEventId(Number(postData['eventId']));
      if(arr1.keys.includes('groupType')) { // when we require to directly jump to chats 
        postData['groupType'] = arr1.params['groupType'] === "true"; // convert from string value(only if 'true' or 'false') to boolean
        postData['id'] = arr1.params['id'];
        postData['jumpedDirectlyToChat'] = true;
        this.chatService.setCurrentToUserDetails(postData['id']);
        this.dataService.setJumpToChatData(postData);// saved jump specific data for retriving later in user list fetch
        this.chatService.checkUserDetailsForVerification(postData, true, true);
      } else {
        this.chatService.checkUserDetailsForVerification(postData, true);
      }
    }
  }

  ngOnDestroy(): void {
    console.log('list of subscriptions before unsubscribing inside ngOnDestroy: ', this.subscriptionArr);
    this.subscriptionArr.unsubscribe();
  }
}
