import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Contacts } from './contacts.model';
import { contacts as dataContact } from './data';
import { TranslateService } from '@ngx-translate/core';
import { UserVerifyService } from 'src/app/services/user-verify.service';
import { ChatService } from '../../services/chat.service';
import { EMPTY, of, Subscription } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { InterComponentDataService } from '../../services/inter-component-data.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
/**
 * Tab-contacts component
 */
export class ContactsComponent implements OnInit, OnDestroy {
  private subscriptionArr: Subscription = new Subscription(); // for tracking all subscription on page

  hideFeaturesForNow = true;// to hide feature not implemented for now

  contacts: any[];
  contactsList: any;
  currentFromUID: string;
  showLoader1 = false;

  constructor(private modalService: NgbModal, public translate: TranslateService, private dataService: InterComponentDataService,
    private userService: UserVerifyService, private chatService: ChatService) { }

  ngOnInit(): void {
    let sub1 = this.dataService.dataReceivedEvent.pipe(concatMap((result: any) => {
      if (result.eventName === 'YourUserId') {
        this.currentFromUID = result.data;
        this.showLoader1 = true;
        return of(result.data);
      } else {
        /* Stopping pipe stream chain in case non-required events fire as it 
        creates error in next concatMap request in pipeline*/
        return EMPTY;
      }
    }),
      concatMap((resData: any) => {
        console.log('resData :: ', resData);
        return this.userService.getContactsList({ userId: resData, type:'users'}).pipe(
          /* Very important to keep outer observable subscription alive if
          inner observable errors out in concatMap*/
          catchError((error) => {
            console.log('!! error in catchError !!', error);
            return EMPTY; // as catchError requires an observable to be emitted 
          }));
      })).subscribe((resp: any) => {
        this.showLoader1 = false;
        console.log('resp in getContactsList:: ', resp);
        this.contacts = resp.data;
        const sorted = this.contacts.sort((a, b) => a.userName > b.userName ? 1 : -1);
        // const sorted = dataContact.sort((a, b) => a.name > b.name ? 1 : -1);
        console.log('Sorted contacts :: ', sorted);
    
        const grouped = sorted.reduce((groups, contact) => {
          const letter = this.translate.instant(contact.userName).charAt(0).toUpperCase();
          groups[letter] = groups[letter] || [];
          groups[letter].push(contact);
    
          return groups;
        }, {});
        console.log('grouped contacts :: ', grouped);
    
        // contacts list
        this.contactsList = Object.keys(grouped).map(key => ({ key, contacts: grouped[key] }));
        console.log('contactsList contacts :: ', this.contactsList);
        // let tempArr = resp.map((elem: any)=> {
        //   this.stepsArr.forEach(item => {
        //     if(elem.imageId===item.target.imageId) {
        //       elem.points.forEach((element:any) => {
        //         if(element.pointsId===item.target.pointsId) {/* Only for disabling extra hotspots than given in steps */
        //           element.disableHotspot = false;
        //         } else {
        //           element.disableHotspot = true;
        //         }        
        //       });
        //     }
        //   });
        //   return elem;
        // })
        // this.ctrlImagesArr = tempArr;
        // console.log('ctrlImagesArr :: ', this.ctrlImagesArr);
      },
      (err: any) => {
      //   this.loading1 = false;
      //   localStorage.clear();
        console.log('error Status', err.status);
        // if (err.status === 401) { // When unauthenticated access due to wrong token or session expire
        //   this.saveRedirectUrlAndLogoutToMainPage();
        // } else { // When unauthenticated access due to reasons like partner info not available, server down
        //   this.userService.logout();
        // }

        // no user login so redirect to login page
        // this.router.navigate(['']);
        // observer.next(false);
        // observer.complete();
      });
    this.subscriptionArr.add(sub1);
  }


  /**
   * Contacts modal open
   * @param content content
   */
  // tslint:disable-next-line: typedef
  openContactsModal(content) {
    this.modalService.open(content, { centered: true });
  }
  
  /**
   * Show user chat
   */
  showChat(userId, toUserName) {
    this.chatService.setCurrentToUserDetails(userId, toUserName);
    this.dataService.setDataToSend({
      eventName: 'GetMessages', //eventname can be anything
      data: {fromUID: this.currentFromUID, toUID: userId},
      resetScrollbar: true
    });
    // document.getElementById('chat-room').classList.add('user-chat-show');
  }

  ngOnDestroy(): void {
    console.log('list of subscriptions before unsubscribing inside ngOnDestroy: ', this.subscriptionArr);
    this.subscriptionArr.unsubscribe();
  }
}
