import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { Observable, Subject } from 'rxjs';
import { SocketOne } from 'src/app/app.module';
import { ToastAlertsService } from 'src/app/reusables/toast-alerts/toast-alerts.service';
import { UserVerifyService } from 'src/app/services/user-verify.service';
import { Message } from '../index/chat.model';
import { InterComponentDataService } from './inter-component-data.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private currentFromUserId: string;
  private currentFromUserName: string;
  private currentFromUserRole: string;
  private currentFromUserEventId: number;
  private currentToUserId: string;
  private currentToUserName: string;
  private suppressInitialRecentChatsWindowLoader: boolean = false;// in case we want to suppress initial loader of recent chats like in unity/mobile
  private suppressInitialMessagesContentWindowLoader: boolean = false;// in case we want to suppress initial loader of message content window like in unity/mobile
  public currentLoggedInFromUserSubject: Subject<any>;
  public currentLoggedInFromUser: Observable<any>;

  private isDisconnected = false;

  private hideInitialLoginPageSubject: Subject<any> = new Subject<any>();/* To hide login page When we login using query params in chat url */
  public hideInitialLoginPage$: Observable<any> = this.hideInitialLoginPageSubject.asObservable();

  constructor(private socket: SocketOne, private dataService: InterComponentDataService
    , private router: Router, private userVerifyService: UserVerifyService, private toastService: ToastAlertsService) {
    socket.on('connect', (res: any)=>{
      console.log('connected :: ', res, 'this.dataService.currentUserObj :: ', this.dataService.currentUserObj);
      if(this.dataService.currentUserObj.hasOwnProperty('isNewUser')
      && this.dataService.currentUserObj.isNewUser){
        this.socket.emit('join', 
        {
          userId: this.currentFromUserId,
          isNewUser: this.dataService.currentUserObj.isNewUser
        });
      } else{
        this.socket.emit('join', 
        {userId: this.currentFromUserId});
      }
      if(this.isDisconnected){
        // show reconnected msg
        this.isDisconnected = false;
      }
      this.dataService.setDataToSend({
        eventName: 'YourUserId', //eventname can be anything
        data: this.currentFromUserId,
        ...(this.getInitialRecentChatsWindowLoaderSuppressStatus && { suppressLoader: this.getInitialRecentChatsWindowLoaderSuppressStatus })
      });
    });                                 
    socket.on('disconnect', function (){
      console.log('disconnected');
      // show error
      this.isDisconnected = true;
    });

    /* Ref - https://github.com/mihaita-tinta/articles/blob/main/messenger/messenger-ui/src/app/websocket/websocket.service.ts */
    socket.on('error', (err: any) => {console.error('Socket encountered error: ', err);});

    socket.on('online-status', (res: any) => {
      console.log('%c online-status : ', "background: violet; color: black", res);
      // this.dataService.setDataToSend({
      //   eventName: 'msgSentAcknowledgedRecieptRecieved', //eventname can be anything
      //   data: res
      // });
      /* We are sending for updating userList in recent chats*/
      // this.dataService.setDataToSend({
      //   eventName: 'YourUserId', //eventname can be anything
      //   data: this.currentFromUserId
      // });
      this.dataService.setDataToSend({
        eventName: 'onlineStatusRefreshed', //eventname can be anything
        data: res
      });
    });

    socket.on('msg-acknowledged', (res: any) => {
      console.log('%c msg-acknowledged : ', "background: darkgreen; color: #bada55", res);
      this.dataService.setDataToSend({
        eventName: 'msgSentAcknowledgedRecieptRecieved', //eventname can be anything
        data: res
      });
      /* We are sending for updating userList in recent chats*/
      this.dataService.setDataToSend({
        eventName: 'refreshOnMsgSent', //eventname can be anything
        data: res
      });
    });

    socket.on('msg-read-reciept', (res: any) => {
      console.log('%c msg-read-reciept: ', "background: grey; color: #bada55", res);
      this.dataService.setDataToSend({
        eventName: 'msgReadRecieptRecieved', //eventname can be anything
        data: res // format:{senderId: '123456', msgIds: '6371e725c8e771b1034af278'}
      });
    });

    socket.on('msg-recieve', (res: any) => {
      console.log('msgRecieved', res);
      if(res && res.hasOwnProperty('join_msg')){
      } 
      else {
        this.dataService.setDataToSend({
          eventName: 'SomeMessageRecieved', //eventname can be anything
          data: res
        });
        /* We are sending for updating lastMsg in userList in recent chats*/
        this.dataService.setDataToSend({
          eventName: 'refreshOnMsgRecieved', //eventname can be anything
          data: res
        });
        /* We are sending for updating/refreshing userList's unread msg count in recent chats*/
        this.refreshUserList(true);
      }
    });

    socket.on('last-msg-deleted', (res: any) => {
      console.log('%c last-msg-deleted: ', "background: red; color: #bada55", res);
      /* We are sending for updating/refreshing userList's last msg in recent chats*/
      this.refreshUserList(true);
    });

    socket.on('typing-recieved', (res: any) => {
      console.log('%c typing-recieved: ', "background: brown; color: #bada55", res);
      this.dataService.setDataToSend({
        eventName: 'typingStatusRecieved', //eventname can be anything
        data: res // format:{senderId: '123456', msgIds: '6371e725c8e771b1034af278'}
      });
    });

    /* Currently only used when we add user in new group */
    socket.on('refresh-user-list', (res: any) => {
      console.log('%c refresh on user added in neww group: ', "background: red; color: #bada55", res);
      /* We are sending for updating/refreshing userList's last msg in recent chats*/
      this.refreshUserList(true);
      this.socket.emit('new-group-addition-acknowledgement', res);
    });

    socket.on('user-banned', (res: any) => {
      console.log('%c user-banned: ', "background: black; color: #bada55", res);
      if(res && res.hasOwnProperty('groupId')){
        this.dataService.setDataToSend({
          eventName: 'userBanRecieved', //eventname can be anything
          data: res // format:{senderId: '123456', msgIds: '6371e725c8e771b1034af278'}
        });
      } else {
        this.refreshUserList(true);
      }
    });
    
  }

	showDanger(msg) {
		this.toastService.show(msg, { classname: 'bg-danger text-light', delay: 3000 });
	}

  refreshUserList(suppressLoader: boolean){
    /* We are sending for updating/refreshing userList in recent chats*/
    this.dataService.setDataToSend({
      eventName: 'YourUserId', //eventname can be anything
      data: this.currentFromUserId,
      ...(suppressLoader && {suppressLoader:suppressLoader})
    });
  }

  public sendUserIdForJoining(userId: string) {
    this.socket.connect();
    this.currentFromUserId = userId;
    // console.log('fetching localstorage :: ', JSON.parse(localStorage.getItem('currentUser')));
    this.setCurrentFromUserName();
    // this.socket.emit('join', userId);
    // this.dataService.setDataToSend({
    //   eventName: 'YourUserId', //eventname can be anything
    //   data: userId
    // });
    // this.socket.disconnect();
  }

  public setHideInitialLoginPageSubject(value:boolean) {
    this.hideInitialLoginPageSubject.next({hideLoginPage:value});
  }

  public checkUserDetailsForVerification(postData, addNavigation, jumpDirectlyToChat?:boolean) {
    console.log('checkUserDetailsForVerification postData :: ', postData);
    this.userVerifyService.checkUserVerification(postData).subscribe(
        (usrData: any) => {
            console.log('data inside subscribe of checkUserVerification::', usrData);
            const userData = {
              uid:usrData.data.userId,
              role:usrData.data.role,
              uname:usrData.data.userName,
              ...(usrData.data.isNewUser && {isNewUser:usrData.data.isNewUser})
            }
            this.dataService.setCurrentUserValue(userData);
            console.log('this.dataService.currentFromUID :: ', this.dataService.currentFromUID);
            this.sendUserIdForJoining(usrData.data.userId);
            if(jumpDirectlyToChat){
                this.router.navigate(['']);
      
                setTimeout(() => {
                  this.dataService.setDataToSend({
                    eventName: 'GetMessages', //eventname can be anything
                    data: {fromUID: this.dataService.currentFromUID, toUID: postData['id'], ...(postData['groupType'] && {type:'group'})},
                    resetScrollbar: true,
                    jumpedDirectlyToChat:true // only when we jump directly to specific chat
                  });
                document.getElementById('chat-room').classList.add('user-chat-show');
              }, 100);
            } else if(addNavigation) {
              // localStorage.setItem('currentUser', JSON.stringify(postData));
              setTimeout(() => {
                this.router.navigate(['']);
              }, 0);
            }
            
        //   this.loading1 = false;
  
        //   localStorage.setItem('usrToken', jwtToken);
  
        //   console.log('data inside subscribe of checkJWTTokenSession() in CM GUARD::', usrData);
          
        //   this.router.navigate(['main']);
        },
        (err: any) => {
        //   this.loading1 = false;
        //   localStorage.clear();
          console.log('error Status', err.status);
          this.showDanger('Something went wrong :: '+ err);
          // if (err.status === 401) { // When unauthenticated access due to wrong token or session expire
          //   this.saveRedirectUrlAndLogoutToMainPage();
          // } else { // When unauthenticated access due to reasons like partner info not available, server down
          //   this.userService.logout();
          // }
  
          // no user login so redirect to login page
          // this.router.navigate(['']);
          // observer.next(false);
          // observer.complete();
        }
      );
  }

  // public SendUserIdForGroupJoining(userId: string, objectId: string) {
  //   this.socket.emit('join-group', {fromUID: userId, toUID: objectId, fromName: this.currentFromUserName});
  // }

  
  public setInitialRecentChatsWindowLoaderSuppressStatus(val: boolean) {
    this.suppressInitialRecentChatsWindowLoader = val;
  }

  public getInitialRecentChatsWindowLoaderSuppressStatus() {
    return this.suppressInitialRecentChatsWindowLoader;
  }
  
  public setInitialMessagesContentWindowLoaderSuppressStatus(val: boolean) {
    this.suppressInitialMessagesContentWindowLoader = val;
  }

  public getInitialMessagesContentWindowLoaderSuppressStatus() {
    return this.suppressInitialMessagesContentWindowLoader;
  }
  
  public setCurrentFromUserEventId(eventId:number) {
    this.currentFromUserEventId = eventId;
  }

  public getCurrentFromUserEventId() {
    return this.currentFromUserEventId;
  }

  
  public setCurrentFromUserName(userName?:string) {
    if(userName) {
      this.currentFromUserName = userName;
    } else {
      this.currentFromUserName = this.dataService.currentUserObj.uname;
    }
  }

  // convenience getter for easy access
  get currentLoggedInUserRole() { return this.currentFromUserRole || this.dataService.currentUserObj.role; }

  public getCurrentToUserName() {
    return this.currentToUserName;
  }

  public getCurrentToUserId() {
    return this.currentToUserId;
  }

  public setCurrentToUserDetails(userId: string, userName?: string) {
    this.currentToUserId = userId;
    if(userName) {
      this.currentToUserName = userName;
    }
    console.log('setCurrentToUserId changed to userId :: ', userId,' : ', userName);
  }

  public sendMessage(message: any, type?: string, isFile?:boolean) {
    console.log('%c sendMessage this.currentToUserId: ', "background: green; color: #bada55", this.currentToUserId);
    
    if(isFile) {
      this.socket.emit('send-msg', {
        file: message, // fileObject in this case
        fromUID: this.currentFromUserId,fromName: this.currentFromUserName,
        toUID: this.currentToUserId, 
        toName: this.currentToUserName,
        isRead:false,
        ...(type && {type:type})
      });
      
      // setInterval(() => console.log('this.socket.ioSocket.bufferedAmount :: ', this.socket.ioSocket.bufferedAmount), 500);
    } else {
      this.socket.emit('send-msg', {
        msg: message,
        fromUID: this.currentFromUserId,fromName: this.currentFromUserName,
        toUID: this.currentToUserId, 
        toName: this.currentToUserName,
        isRead:false,
        ...(type && {type:type})
      });
    }
    // this.socket.disconnect();
  }

  public SendReadRecieptThruSocket(postData: any) {
    this.socket.emit('read-msg-reciept', {
      userId: postData['userId'], // user currently logged in as msg reciever 
      msgIds: postData['msgIds'],
      ...(postData.hasOwnProperty('type') && {type:postData['type']})
    }
    );
  }

  public notifyTyping(data) {
    this.socket.emit('typing-sent', data);
  }

  public notifyAttachmentSent(data) {
    this.socket.emit('attachment-sent', data);
  }

  public getMessages = () => {
    return new Observable((observer) => {
      // this.socket.emptyConfig = { url: 'http://79a0-114-31-129-162.ngrok.io/', options: {} };
      // this.socket.connect();
      this.socket.on('new-message', (message: Message) => {
        observer.next(message);
      });
    });
  }
}
