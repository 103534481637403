 <!-- Start Groups content -->
 <div>
   <div class="p-4">
     <div class="user-chat-nav float-end" *ngIf="!hideFeaturesForNow">
       <div ngbTooltip="Create group">
         <!-- Button trigger modal -->
         <button type="button" class="btn btn-link text-decoration-none text-muted font-size-18 py-0"
           data-toggle="modal" data-target="#addgroup-exampleModal" (click)="openGroupModal(content)">
           <i class="ri-group-line me-1"></i>
         </button>
       </div>
     </div>
     <h4 class="mb-4">{{'chat.tabs.groups.title' | translate}}</h4>

     <!-- Start add group Modal -->
     <ng-template #content let-modal>
       <div class="modal-content">
         <div class="modal-header">
           <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
             {{'chat.tabs.groups.modal.title' | translate}}</h5>
           <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
             (click)="modal.dismiss('Cross click')">
           </button>
         </div>
         <div class="modal-body p-4">
           <form>
             <div class="mb-4">
               <label class="form-label" for="addgroupname-input">{{'chat.tabs.groups.modal.form.name.label' | translate}}</label>
               <input type="text" class="form-control" id="addgroupname-input"
                 placeholder="{{'chat.tabs.groups.modal.form.name.placeholder' | translate}}">
             </div>
             <div class="mb-4">
               <label class="form-label">{{'chat.tabs.groups.modal.form.members.label' | translate}}</label>
               <div class="mb-3">
                 <button class="btn btn-light btn-sm" type="button" data-toggle="collapse"
                   (click)="isCollapsed = !isCollapsed" data-target="#groupmembercollapse" aria-expanded="false"
                   aria-controls="groupmembercollapse">
                   {{'chat.tabs.groups.modal.form.members.button.text' | translate}}
                 </button>
               </div>

               <div class="collapse" id="groupmembercollapse" [ngbCollapse]="isCollapsed">
                 <div class="card border">
                   <div class="card-header">
                     <h5 class="font-size-15 mb-0">{{'chat.tabs.contacts.title' | translate}}</h5>
                   </div>
                   <div class="card-body p-2">
                     <perfect-scrollbar data-simplebar style="height: 150px;">
                       <div>
                         <div class="p-3 font-weight-bold text-primary">
                           {{'chat.tabs.contacts.list.A.text' | translate}}
                         </div>

                         <ul class="list-unstyled contact-list">
                           <li>
                             <div class="form-check">
                              <input type="checkbox" class="form-check-input" id="memberCheck1" checked="">
                              <label class="form-check-label" for="memberCheck1">{{'chat.tabs.contacts.list.A.name' | translate}}</label>
                            </div>
                           </li>
                           <li>
                             <div class="form-check">
                               <input type="checkbox" class="form-check-input" id="memberCheck2">
                               <label class="form-check-label"
                                 for="memberCheck2">{{'chat.tabs.contacts.list.A.name2' | translate}}</label>
                             </div>
                           </li>
                         </ul>
                       </div>

                       <div>
                         <div class="p-3 font-weight-bold text-primary">
                           {{'chat.tabs.contacts.list.C.text' | translate}}
                         </div>

                         <ul class="list-unstyled contact-list">
                           <li>
                             <div class="form-check">
                               <input type="checkbox" class="form-check-input" id="memberCheck3">
                               <label class="form-check-label" for="memberCheck3">
                                 {{'chat.tabs.contacts.list.C.name' | translate}}</label>
                             </div>
                           </li>
                         </ul>
                       </div>

                       <div>
                         <div class="p-3 font-weight-bold text-primary">
                           {{'chat.tabs.contacts.list.D.text' | translate}}
                         </div>

                         <ul class="list-unstyled contact-list">
                           <li>
                             <div class="form-check">
                               <input type="checkbox" class="form-check-input" id="memberCheck4">
                               <label class="form-check-label" for="memberCheck4">
                                 {{'chat.tabs.contacts.list.D.name' | translate}}</label>
                             </div>
                           </li>
                         </ul>
                       </div>

                       <div>
                         <div class="p-3 font-weight-bold text-primary">
                           {{'chat.tabs.contacts.list.I.text' | translate}}
                         </div>

                         <ul class="list-unstyled contact-list">
                           <li>
                             <div class="form-check">
                               <input type="checkbox" class="form-check-input" id="memberCheck5">
                               <label class="form-check-label" for="memberCheck5">
                                 {{'chat.tabs.contacts.list.I.name' | translate}}</label>
                             </div>
                           </li>

                         </ul>
                       </div>

                       <div>
                         <div class="p-3 font-weight-bold text-primary">
                           {{'chat.tabs.contacts.list.J.text' | translate}}
                         </div>

                         <ul class="list-unstyled contact-list">
                           <li>
                             <div class="form-check">
                               <input type="checkbox" class="form-check-input" id="memberCheck6">
                               <label class="form-check-label"
                                 for="memberCheck6">{{'chat.tabs.contacts.list.J.name' | translate}}</label>
                             </div>
                           </li>

                           <li>
                             <div class="form-check">
                               <input type="checkbox" class="form-check-input" id="memberCheck7">
                               <label class="form-check-label"
                                 for="memberCheck7">{{'chat.tabs.contacts.list.J.name2' | translate}}</label>
                             </div>
                           </li>

                           <li>
                             <div class="form-check">
                               <input type="checkbox" class="form-check-input" id="memberCheck8">
                               <label class="form-check-label"
                                 for="memberCheck8">{{'chat.tabs.contacts.list.J.name3' | translate}}</label>
                             </div>
                           </li>

                         </ul>
                       </div>

                       <div>
                         <div class="p-3 font-weight-bold text-primary">
                           {{'chat.tabs.contacts.list.M.text' | translate}}
                         </div>

                         <ul class="list-unstyled contact-list">
                           <li>
                             <div class="form-check">
                               <input type="checkbox" class="form-check-input" id="memberCheck9">
                               <label class="form-check-label"
                                 for="memberCheck9">{{'chat.tabs.contacts.list.M.name' | translate}}</label>
                             </div>
                           </li>

                           <li>
                             <div class="form-check">
                               <input type="checkbox" class="form-check-input" id="memberCheck10">
                               <label class="form-check-label"
                                 for="memberCheck10">{{'chat.tabs.contacts.list.M.name2' | translate}}</label>
                             </div>
                           </li>

                         </ul>
                       </div>

                       <div>
                         <div class="p-3 font-weight-bold text-primary">
                           {{'chat.tabs.contacts.list.P.text' | translate}}
                         </div>

                         <ul class="list-unstyled contact-list">
                           <li>
                             <div class="form-check">
                               <input type="checkbox" class="form-check-input" id="memberCheck11">
                               <label class="form-check-label" for="memberCheck11">
                                 {{'chat.tabs.contacts.list.P.name' | translate}}</label>
                             </div>
                           </li>

                         </ul>
                       </div>

                       <div>
                         <div class="p-3 font-weight-bold text-primary">
                           {{'chat.tabs.contacts.list.R.text' | translate}}
                         </div>

                         <ul class="list-unstyled contact-list">
                           <li>
                             <div class="form-check">
                               <input type="checkbox" class="form-check-input" id="memberCheck12">
                               <label class="form-check-label" for="memberCheck12">
                                 {{'chat.tabs.contacts.list.R.name' | translate}}</label>
                             </div>
                           </li>

                         </ul>
                       </div>

                       <div>
                         <div class="p-3 font-weight-bold text-primary">
                           {{'chat.tabs.contacts.list.S.text' | translate}}
                         </div>

                         <ul class="list-unstyled contact-list">
                           <li>
                             <div class="form-check">
                               <input type="checkbox" class="form-check-input" id="memberCheck13">
                               <label class="form-check-label"
                                 for="memberCheck13">{{'chat.tabs.contacts.list.S.name' | translate}}</label>
                             </div>
                           </li>
                         </ul>
                       </div>
                     </perfect-scrollbar>
                   </div>

                 </div>
               </div>
             </div>
             <div class="form-group">
               <label class="form-label"
                 for="addgroupdescription-input">{{'chat.tabs.groups.modal.form.description.label' | translate}}</label>
               <textarea class="form-control" id="addgroupdescription-input" rows="3"
                 placeholder="{{'chat.tabs.groups.modal.form.description.placeholder' | translate}}"></textarea>
             </div>
           </form>
         </div>
         <div class="modal-footer">
           <button type="button" class="btn btn-link" data-dismiss="modal"
             (click)="modal.dismiss('Cross click')">{{'chat.tabs.groups.modal.form.button.close' | translate}}</button>
           <button type="button"
             class="btn btn-primary">{{'chat.tabs.groups.modal.form.button.create' | translate}}</button>
         </div>
       </div>
     </ng-template>
     <!-- End add group Modal -->

     <div class="search-box chat-search-box" *ngIf="!hideFeaturesForNow">
       <div class="input-group mb-3 rounded-3">
           <button class="input-group-text text-muted bg-light pe-1 ps-3" type="button">
             <i class="ri-search-line search-icon font-size-18"></i>
           </button>
         <input type="text" class="form-control bg-light"
           placeholder="{{'chat.tabs.groups.search.placeholder' | translate}}">
       </div>
     </div>
     <!-- end search-box -->
   </div>

   <!-- Start chat-group-list -->
   <perfect-scrollbar class="p-4 chat-message-list chat-group-list" data-simplebar *ngIf="groupsList">
     <ul class="list-unstyled chat-list">
       <li *ngFor="let item of groupsList" (click)="showChat(item._id, item.group_name)">
         <a href="#">
           <div class="d-flex align-items-center">
             <div class="chat-user-img me-3 ms-0">
               <div class="avatar-xs">
                 <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                   {{item.group_name.charAt(0)}}
                 </span>
               </div>
             </div>
             <div class="flex-1 overflow-hidden">
               <h5 class="text-truncate font-size-14 mb-0">#{{item.group_name | translate}}
                 <span class="badge badge-soft-danger rounded-pill float-end">{{item.unread}}</span>
               </h5>
             </div>
           </div>
         </a>
       </li>
     </ul>
   </perfect-scrollbar>
   <!-- End chat-group-list -->
    
  <app-ui-loader class="position-relative d-block" *ngIf="showLoader1"
  style="margin-top: 50%;"></app-ui-loader>
 </div>
 <!-- End Groups content -->