import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-loader',
  templateUrl: './ui-loader.component.html',
  styleUrls: ['./ui-loader.component.scss']
})
export class UiLoaderComponent implements OnInit {
  @Input() transparentBackground: boolean = true;

  constructor() { 
    console.log('LOADING :: ');
  }

  ngOnChanges(changes) {
    console.log('Changed', changes.transparentBackground.currentValue, changes.transparentBackground.previousValue);
  }

  ngOnInit(): void {
    console.log('Init', this.transparentBackground);
  }

}
