import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Injectable, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ChatModule } from './chat/chat.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
// import { initFirebaseBackend } from './authUtils';
import { FakeBackendInterceptor } from './core/helpers/fake-backend';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { SocketIoModule, SocketIoConfig, Socket } from 'ngx-socket-io';
import { Configurations } from './services/configurations.service';
import { DatePipe } from '@angular/common';
import { SharedModule } from './reusables/shared.module';

if (environment.defaultauth === 'firebase') {
  import("./authUtils").then(allM => {//dynamic module loading
    // JUST USE THE LIBRARY
    allM.initFirebaseBackend(environment.firebaseConfig);
  });
  // const initFirebaseBackend = (await import('./authUtils'));
  // initFirebaseBackend(environment.firebaseConfig);
} else {
  // tslint:disable-next-line: no-unused-expression
  FakeBackendInterceptor;
}

export function createTranslateLoader(http: HttpClient): any {
  /* TranslateHttpLoader to load translations from "/assets/i18n/[lang].json" 
  ([lang] is the lang that you're using, for english it could be en) and make
  that data available throughout app*/
  return new TranslateHttpLoader(http);
}

// const config: SocketIoConfig = { url: 'http://79a0-114-31-129-162.ngrok.io/', options: {autoConnect: false} };

@Injectable()
export class SocketOne extends Socket {
  constructor(private cfig: Configurations) {/* This will be same as base url in configuration.service.ts file*/
    // super({ url: cfig.baseUrl+'/', options: {autoConnect: false, transports: ['websocket']} });

    /* uWebsocket requirement on server withh different port than one used for api*/
    // super({ url: cfig.baseUrl+':8001'+'/', options: {autoConnect: false, transports: ['websocket']} });

    /* uWebsocket requirement on server withh different port than one used for api*/
    super({ url: 'https://ws-chat.exposim.io/', options: {autoConnect: false, transports: ['websocket']} });
    
    // super({ url: 'http://192.168.1.2:8001'+'/', options: {autoConnect: false, transports: ['websocket']} });
    console.log('[[socketOne]] cfig.baseUrl :: ', cfig.baseUrl);
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    ChatModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SocketIoModule,
  ],
  providers: [
    Configurations,
    DatePipe,
    SocketOne,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
