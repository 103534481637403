import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, of, Subscription } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { ToastAlertsService } from 'src/app/reusables/toast-alerts/toast-alerts.service';
import { UserVerifyService } from 'src/app/services/user-verify.service';
import { ChatService } from '../../services/chat.service';
import { InterComponentDataService } from '../../services/inter-component-data.service';

import { groups as dataGroup } from './data';
import { Groups } from './groups.model';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})

/**
 * Tabs-group component
 */
export class GroupsComponent implements OnInit {
  private subscriptionArr: Subscription = new Subscription(); // for tracking all subscription on page

  hideFeaturesForNow = true;// to hide feature not implemented for now

  public isCollapsed: boolean;
  groups: any[];
  groupsList: any;
  currentFromUID: string;
  showLoader1 = false;

  constructor(private modalService: NgbModal, private dataService: InterComponentDataService,
    private userService: UserVerifyService, private chatService: ChatService, private toastService: ToastAlertsService) { }

  ngOnInit(): void {
    let sub1 = this.dataService.dataReceivedEvent.pipe(concatMap((result: any) => {
      if (result.eventName === 'YourUserId') {
        this.currentFromUID = result.data;
        this.showLoader1 = true;
        return of(result.data);
      } else {
        /* Stopping pipe stream chain in case non-required events fire as it 
        creates error in next concatMap request in pipeline*/
        return EMPTY;
      }
    }),
      concatMap((resData: any) => {
        console.log('resData :: ', resData);
        return this.userService.getContactsList({ userId: resData, type:'group'}).pipe(
          /* Very important to keep outer observable subscription alive if
          inner observable errors out in concatMap*/
          catchError((error) => {
            console.log('!! error in catchError !!', error);
            this.showDanger('Something went wrong :: '+ error);
            return EMPTY; // as catchError requires an observable to be emitted 
          }));
      })).subscribe((resp: any) => {
        this.showLoader1 = false;
        console.log('resp in getContactsList:: ', resp);
        this.groupsList = resp.data.sort((a, b) => a.group_name > b.group_name ? 1 : -1);
        // const sorted = dataContact.sort((a, b) => a.group_name > b.group_name ? 1 : -1);
        console.log('Sorted groups :: ', this.groupsList);
      },
      (err: any) => {
      //   this.loading1 = false;
      //   localStorage.clear();
        console.log('error Status', err.status);
        this.showDanger('Something went wrong :: '+ err);
        // if (err.status === 401) { // When unauthenticated access due to wrong token or session expire
        //   this.saveRedirectUrlAndLogoutToMainPage();
        // } else { // When unauthenticated access due to reasons like partner info not available, server down
        //   this.userService.logout();
        // }

        // no user login so redirect to login page
        // this.router.navigate(['']);
        // observer.next(false);
        // observer.complete();
      });
    this.subscriptionArr.add(sub1);
    // this.groups = groups;

    // collpsed value
    this.isCollapsed = true;
  }

	showDanger(msg) {
		this.toastService.show(msg, { classname: 'bg-danger text-light', delay: 3000 });
	}

  /**
   * Open add group modal
   * @param content content
   */
  // tslint:disable-next-line: typedef
  openGroupModal(content: any) {
    this.modalService.open(content, { centered: true });
  }
  
  /**
   * Show user chat
   */
  showChat(userId, toUserName) {
    this.chatService.setCurrentToUserDetails(userId, toUserName);
    this.dataService.setDataToSend({
      eventName: 'GetMessages', //eventname can be anything
      data: {fromUID: this.currentFromUID, toUID: userId, type:'group'},
      resetScrollbar: true
    });
    // this.chatService.SendUserIdForGroupJoining(this.currentFromUID, userId);
    // document.getElementById('chat-room').classList.add('user-chat-show');
  }

  ngOnDestroy(): void {
    console.log('list of subscriptions before unsubscribing inside ngOnDestroy: ', this.subscriptionArr);
    this.subscriptionArr.unsubscribe();
  }
}
